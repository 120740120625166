import {
  createStore,
  // please remove below on production
  applyMiddleware,
  // compose
} from 'redux'

import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'

// import save and load state from storage util
// import { saveState, loadState } from '../util/storageUtils'
import { saveState } from '../util/storageUtils'

import rootReducer from './rootReducers'

const middleware = [thunk]
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// const persistedState = loadState()

const store = createStore(
  rootReducer,
  // persistedState,
  // composeEnhancers(applyMiddleware(...middleware)),
  applyMiddleware(...middleware)
)

store.subscribe(throttle(() => {
  saveState({
    register: store.getState().register
  })
}))

export default store