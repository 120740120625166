import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col, Divider, Tag, Skeleton, Tabs } from 'antd'
import BannerComponent from '../../components/banner';
import { Summary } from './components/summary';
import { isMobile } from 'react-device-detect';
// import Shirtsize from './../../data/shirtsize'

const RenderSummary = ({
  firstname,
  lastname,
  gender,
  phone,
  email,
  quantity
}) => {
  return <Summary
    firstname={firstname}
    lastname={lastname}
    gender={gender}
    phone={phone}
    email={email}
    quantity={quantity}

  />
}

class SummPage extends Component {

  constructor(props) {
    super(props)
    const { panes } = this.props.register

    this.state = {
      loading: true,
      panes,
      activeKey: "0"
    }
  }

  onChange = value => {
    this.setState({ activeKey: value })
  }

  componentDidMount = async () => {
    setTimeout(this.setState({ loading: false }), 5000)
  }

  render() {
    const { formvalue } = this.props.register

    return (
      <>
        <BannerComponent />
        <div className="mg-top-20 mg-bottom-20">
          <Row type="flex" justify="center">
            <Col>
              <Tag color="orange">
                <div className="pd-top-20 pd-bottom-20">
                  <span className="form-title-red txt-center">ຢືນຢັນຂໍ້ມູນ / Confirm Info</span>
                </div>
              </Tag>
            </Col>
          </Row>
        </div>

        <Divider />
        <Skeleton loading={this.state.loading}>

          <Row type="flex" className={!isMobile ? "mg-left-50 mg-right-50 mg-top-20" :
            "mg-left-20 mg-right-20 mg-top-10"}
          >
            <RenderSummary
              firstname={formvalue.attendee.first_name}
              lastname={formvalue.attendee.last_name}
              gender={formvalue.attendee.gender}
              phone={formvalue.attendee.phone}
              email={formvalue.attendee.email}
              quantity={formvalue.quantity}
            />
          </Row>
        </Skeleton>

      </>
    )
  }
}

const mapStateToProps = state => ({
  register: state.register
})

export default connect(mapStateToProps)(SummPage)