import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Menu, Icon } from 'antd';
import litd_logo from "./../../assets/images/ewent.jpg";
// import logo from "./../../assets/images/BFH_2020.png"
const { SubMenu } = Menu;
const { Sider } = Layout;
class SideBar extends Component {
    onCollapse = collapsed => {
        this.props.setCollapsed(collapsed);
    };
    render() {
        let style_litd = {
            width: "63px",
            height: "63px"
        }
        let hideName = "";
        const { collapsed } = this.props
        if (collapsed) {
            style_litd = {
                width: "63px",
                height: "63px"
            }
            hideName = "hideName";
        }
        return (
            <Fragment>
                <Sider style={{
                    overflowX: "hidden",
                    overflowY: "auto",
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }} collapsible collapsed={collapsed} onCollapse={this.onCollapse} breakpoint={'xl'}>
                    <div className="logo bt-1 bb-1 mt-5" >
                        {/* <img src={logo} style={style} alt="" /> */}
                    </div>
                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                        <Menu.Item key="1">
                            <Link to="/nimda/dashboard">
                                <Icon type="dashboard" />
                                <span>ພາບລວມລະບົບ</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Link to="/nimda/registrations">
                                <Icon type="user"></Icon>
                                <span>ລາຍການລົງທະບຽນ</span>
                            </Link>
                        </Menu.Item>
                        <SubMenu
                            key="sub3"
                            title={
                                <span>
                                    <Icon type="area-chart" />
                                    <span>ລາຍງານ</span>
                                </span>
                            }
                        >
                            <Menu.Item key="11">
                                <Link to="/nimda/report_registers">ລາຍງານລົງທະບຽນ</Link>
                            </Menu.Item>
                            {/* <Menu.Item key="10"> */}
                            {/* <Link to="/nimda/report_runners">ລາຍງານຜູ່ສະໝັກ</Link> */}
                            {/* </Menu.Item> */}
                            {/* <Menu.Item key="11">ລາຍງານລາຄາສະໝັກ</Menu.Item>
                            <Menu.Item key="12">ລາຍງານຂະໜາດເສືອ</Menu.Item> */}
                        </SubMenu>
                        <SubMenu
                            key="sub2"
                            title={
                                <span>
                                    <Icon type="setting" />
                                    <span>ຕັ້ງຄ່າ</span>
                                </span>
                            }
                        >
                            <Menu.Item key="3">
                                <Link to="/nimda/users">
                                    <Icon type="user"></Icon>
                                    <span>ລາຍຊື່ຜູ່ໃຊ້ງານ</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="6">
                                <Link to="/nimda/distances">
                                    ປະເພດບັດ
                                </Link>
                            </Menu.Item>
                            {/* <Menu.Item key="8">
                                <Link to="/nimda/tshirt_sizes">
                                    ຂະໜາດເສື້ອ
                                </Link>
                            </Menu.Item> */}

                        </SubMenu>
                    </Menu>
                    <div className="bt-1 bb-1 mt-5" style={{ textAlign: "center", padding: "5px 0px" }}>
                        <div className={`font-w ${hideName}`}>ພັດທະນາໂດຍ: </div>
                        <img src={litd_logo} style={style_litd} alt="" />
                        <div className={`font-w ${hideName}`}>.....</div>
                        <div className={`font-w ${hideName}`}>021 562905</div>
                        <div className={`font-w ${hideName}`}>......</div>
                        <div className={`font-w ${hideName}`}>info@laoitdev.com</div>
                    </div>
                </Sider>
            </Fragment>
        )
    }

}
const mapStateToProp = (state) => {
    return {
        collapsed: state.collapsed
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCollapsed: (collapsed) => {
            dispatch({
                type: "setCollapsed",
                payload: collapsed
            })
        }
    }
}
export default connect(mapStateToProp, mapDispatchToProps)(SideBar)