import React, { Component } from 'react';
import { Layout, Avatar, Menu, Dropdown, Icon, Button} from 'antd';
import './../../assets/css/custom.css'
const { Header } = Layout;
class HeaderBar extends Component{
    logout = (e) => {
        e.preventDefault();
        localStorage.clear();
        this.props.history.push("/nimda/login");
    }
    render(){
        let {first_name, last_name} = this.props.state
        const menu = (
            <Menu>
                <Menu.Item key="1">
                    <Button type="link" href="#" onClick={this.logout}>ອອກຈາກລະບົບ</Button>
                </Menu.Item>
            </Menu>
        )
        first_name = first_name !== undefined ? first_name : ""
        last_name = last_name !== undefined ? last_name : ""
        return (
            <Header style={{ background: '#fff', padding: 0 }}>
                <div className="profile">
                    <Avatar size="large" icon="user" style={{marginRight: "10px"}} /> 
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Button type="link" className="ant-dropdown-link" href="#">
                        ສະບາຍດີ {`${first_name} ${last_name}`}<Icon type="down" />
                        </Button>
                    </Dropdown>
                </div>
            </Header>
        )
    }
}
export default HeaderBar