import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

// import Approot
import AppRoot from './AppRoot';

// imported css
// import './index.css'
import './App.css'
import 'antd/dist/antd.css';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import './registration/assets/css/text_style.css'
import './registration/assets/css/default_regis.css'

ReactDOM.render(<AppRoot />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
