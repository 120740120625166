import React from "react";
import { Card, Button, Modal, Table, Divider } from "antd";
import { axiosInstant } from "../../../../service/axios";
class Distance extends React.Component {
  state = {
    visibleModal: false,
    races: []
  };
  componentDidMount(){
      axiosInstant.get(`/races`).then(({data: {races}}) => {
        this.setState({races})
      })
  }

  showModal = () => {
    this.setState({visibleModal: true})
  }
  handleCancel = () => {
      this.setState({visibleModal: false})
  }
  render() {
    const columns = [
      {
        title: "ລຳດັບ",
        dataIndex: "number",
        key: "number"
      },
      {
        title: "ໄລຍະ",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "ລາຄາ",
        dataIndex: "price",
        key: "price"
      },
      {
        title: "Policy",
        dataIndex: "policy",
        key: 'policy'
      },
      {
        title: "ຕັ້ງຄ່າ",
        dataIndex: "setting",
        key: "setting",
        render: item => {
          return (
            <span>
              <Button type="link" onClick={e => this.showUpdate(e, item)}>ແກ້ໄຂ</Button>{" "}
              <Divider type="vertical" />
              <Button type="link" onClick={e => this.showDeleteConfirm(e, item.code)}>ລຶບ</Button>
            </span>
          );
        }
      }
    ];
    const {races} = this.state
    return (
      <>
        <Card
          title="ລາຍການໄລຍະທາງ"
          extra={
            <Button type="primary" onClick={this.showModal}>
              ເພີ່ມໄລຍະທາງ
            </Button>
          }
        >
        <Table columns={columns} dataSource={
          races.map((race, index) => (
            {
              key:index + 1,
              number: index+1,
              name: race.name,
              policy: `ອາຍຸຕ້ອງຫຼາຍກວ່າ ${race.policy.min_attendee_age}`,
              price: race.price,
              setting: race
            }
          ))
        } />
        </Card>
        {this.state.visibleModal && (
          <Modal
            title="ເພີ່ມ Coupon"
            visible={this.state.visibleModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            style={{ fontFamily: "Phetsarath OT" }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
          >
            {/* <AddCouPonModal bindinsert={this.bindInsert} /> */}
          </Modal>
        )}
      </>
    );
  }
}
export default Distance;
