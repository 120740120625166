import React, { Component, Fragment } from "react";
import { axiosInstant } from "./../../../../service/axios";
import { Col, Card, Table, Modal, Form, Input, Button, Divider } from "antd";
const { confirm } = Modal;
class Sizes extends Component {
  state = {
    items: [],
    visible: false,
    visible2: false,
    item: [],
    name: 0
  };
  componentDidMount() {
    axiosInstant
      .get("/shirt-sizes")
      .then(({data: {shirts}}) => {
        this.setState({ items: shirts });
      })
      .then(e => { });
  }
  showModal = () => {
    this.setState({ visible: true });
  };
  showUpdate = (e, item) => {
    e.preventDefault();
    this.setState({ visible2: true });
    this.setState({ name: item });
  };
  handleUpdate = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        axiosInstant
          .patch("/shirt-sizes/" + values.code, { code: values.code })
          .then(res => {
            const newItem = [...this.state.items];
            newItem.forEach(item => {
              if (item.code === values.code) {
                item.code = values.code;
              }
            });
            this.setState({ items: newItem, visible2: false });
          })
          .catch(error => { });
      }
    });
  };
  handleConfirm = code => {
    axiosInstant
      .delete("/shirt-sizes" + code)
      .then(res => {
        const newItems = this.state.items.filter(item => item.code !== code);
        this.setState({ items: newItems });
      })
      .catch(error => { });
  };
  showDeleteConfirm = (e, code) => {
    e.preventDefault();
    confirm({
      title: "ທ່ານຕ້ອງການລົບຂໍ້ມູນນີ້ ຫຼື ບໍ່?",
      content: "ການລົບອາດຈະມີການກະທົບກັບຂໍ້ມູນ ອື່ນ",
      okText: "ຕົກລົງ",
      okType: "danger",
      cancelText: "ຍົກເລີກ",
      style: { fontFamily: "Phetsarath OT" },
      onOk: () => {
        this.handleConfirm(code);
      },
      onCancel: () => { }
    });
  };
  handleCancel = () => {
    this.setState({ visible: false, visible2: false });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        axiosInstant
          .post("/shirtsizes", values)
          .then(res => {
            if (res.status === 201) {
              const newItem = [...this.state.items, res.data];
              this.setState({ items: newItem, visible: false });
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    });
  };

  showDeleteConfirm = (e, code) => {
    e.preventDefault();
    confirm({
      title: "ລຶບຂໍ້ມູນ",
      content: "ເຈົ້າຕ້ອງການລຶບຂໍ້ມູນນີ້ຫຼືບໍ່?",
      okText: "ຍືນຍັນ",
      okType: "danger",
      cancelText: "ຍົກເລີກ",
      onOk: () => {
        this.handleDelete(code);
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };
  handleDelete = code => {
    axiosInstant.delete(`/shirtsizes/${code}`).then(res => {
      const items = this.state.items;
      const newItems = items.filter(item => item.code !== code)
      this.setState({ items: newItems })
    }).catch()
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "ລຳດັບ",
        dataIndex: "number",
        key: "number"
      },
      {
        title: "ຊື່ຂະໜາດ",
        dataIndex: "code",
        key: "code"
      },
      {
        title: "ຮອບເອິກ",
        dataIndex: "bust",
        key: "bust"
      },
      {
        title: "ຕັ້ງຄ່າ",
        dataIndex: "setting",
        key: "setting",
        render: item => {
          return (
            <span>
              <Button type="link" onClick={e => this.showUpdate(e, item)}>ແກ້ໄຂ</Button>{" "}
              <Divider type="vertical" />
              <Button type="link" onClick={e => this.showDeleteConfirm(e, item.code)}>ລຶບ</Button>
            </span>
          );
        }
      }
    ];
    const {items} = this.state
    return (
      <Fragment>
        <Col lg={12} md={24}>
          <Card
            title="ລາຍການຂະໜາດເສື້ອ"
            extra={
              <Button type="primary" onClick={this.showModal}>
                ເພີ່ມຂະໜາດເສື້ອ
              </Button>
            }
          >
            <Table
              columns={columns}
              dataSource={items.map((item, i) => {
                return {
                  key: i,
                  number: (i += 1),
                  code: item.code,
                  bust: item.bust,
                  setting: item
                };
              })}
            ></Table>
          </Card>
        </Col>
        {this.state.visible && (
          <Modal
            title="ເພີ່ມຂະໜາດເສື້ອ"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            style={{ fontFamily: "Phetsarath OT" }}
          >
            <Form onSubmit={this.handleSubmit}>
              <Form.Item label="ຊື່">
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "ກະລຸນາປ້ອນຂໍ້ມູນ!"
                    }
                  ]
                })(<Input placeholder="ປ້ອນຊື່" />)}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  ບັນທຶກ
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
        {this.state.visible2 && (
          <Modal
            title="ແກ້ໄຂໝວດເລືອດ"
            visible={this.state.visible2}
            okButtonProps={{ hidden: true }}
            onCancel={this.handleCancel}
            cancelButtonProps={{ hidden: true }}
            style={{ fontFamily: "Phetsarath OT" }}
          >
            <Form onSubmit={this.handleUpdate}>
              <Form.Item>
                {getFieldDecorator("id", {
                  initialValue: this.state.name.code,
                  rules: [
                    {
                      required: true,
                      message: "ກະລຸນາປ້ອນຂໍ້ມູນ!"
                    }
                  ]
                })(<Input type="hidden" />)}
              </Form.Item>
              <Form.Item label="ຊື່">
                {getFieldDecorator("name", {
                  initialValue: this.state.name.code,
                  rules: [
                    {
                      required: true,
                      message: "ກະລຸນາປ້ອນຂໍ້ມູນ!"
                    }
                  ]
                })(<Input placeholder="ປ້ອນຊື່" />)}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  ບັນທຶກ
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </Fragment>
    );
  }
}
export default Form.create()(Sizes);
