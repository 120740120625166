import React, { Component } from "react";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
import { axiosInstant } from "../../../../service/axios";
import moment from "moment";
import Axios from "axios";
const { Option } = Select;
class EditAttendeeEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      races: [],
      shirt_sizes: [],
      countries: [],
      nationalities: [],
      isLoading: false
    };
  }

  componentDidMount() {
    Promise.all([
      axiosInstant.get('/shirt-sizes')]
    ).then(([{ data: { shirts } }]) => {
      this.setState({
        shirt_sizes: shirts,
        isLoading: false
      })
    }).catch(error => {

    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          // email: values.email,
          // phone: values.phone,
          // emergency_contact: values.emergency_contact,
          attendee: {

            first_name: this.props.item.attendee.first_name,
            last_name: this.props.item.attendee.last_name,
            gender: this.props.item.attendee.gender,
            birth_day: this.props.item.attendee.birth_day,
            blood: this.props.item.attendee.blood,
            identity: this.props.item.attendee.identity,
            contact: {
              email: values.email,
              phone: this.props.item.attendee.contact.phone,
              emergency: this.props.item.attendee.contact.emergency
            },
            address: {
              street: this.props.item.attendee.address.street,
              city: this.props.item.attendee.address.city,
              state: this.props.item.attendee.address.state,
              country: this.props.item.attendee.address.country,
            },
            nationality: this.props.item.attendee.nationality,
            shirt_size: values.shirt_size,
            race: { id: this.props.item.attendee.race.id }
          }
        }
        axiosInstant
          .put(`/books/${this.props.item.id}/attendees/${this.props.item.attendee.id}`, data)
          .then(res => {
            this.props.form.resetFields();
            if (res.status === 204) {
              axiosInstant
                .get(`/books/${this.props.item.id}/mail`)
                .then(res => {
                  if (res.status === 204) {
                    setTimeout(() => { window.location.reload() }, 100)
                  }
                })
                .catch(error => {

                });
            }
          })
          .catch(error => { });
      } else {
        console.log(err)
      }
    });
  };
  render() {
    const { isLoading } = this.state
    const { getFieldDecorator } = this.props.form;
    const item = this.props.item;
    // console.log(item);
    return (

      <>
        {!isLoading &&
          <Form onSubmit={this.handleSubmit} className="login-form">
            <div className="gutter-example">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label={<span>ອີເມລ Email (ຕ້ອງໃຊ້ເພື່ອຮັບໃບບິນຮັບເງິນ)</span>}
                  >
                    {getFieldDecorator("email", {
                      initialValue: item.attendee.contact.email,
                      rules: [
                        {
                          type: "email",
                          message: "ຮູບແບບອີເມວບໍຖືກຕ້ອງ!"
                        },
                        {
                          required: true,
                          message: "ກະລຸນາປ້ອນ ອີເມວ!"
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ຂະໜາດເສື້ອ T-shirt Size">
                    {getFieldDecorator(`shirt_size`, {
                      validateTrigger: ["onChange", "onBlur"],
                      initialValue: item.attendee.shirt_size,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາເລືອກຂະໜາດເສື້ອ"
                        }
                      ]
                    })(
                      <Select style={{ width: "100%" }}>
                        <Option className="form-label-15" key="0" value="">
                          Choose
                      </Option>
                        {this.state.shirt_sizes.map(item => (
                          <Option
                            className="form-label-15"
                            key={item.code}
                            value={item.code}
                          >
                            {item.code}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                ບັນທຶກ
            </Button>
            </div>
          </Form>}
      </>
    );
  }
}

export default Form.create()(EditAttendeeEmail);
