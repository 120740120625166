import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col } from 'antd'
import BannerComponent from '../../components/banner'
// import { isMobile } from 'react-device-detect'
// import Image from 'react-image-webp'

class SuccessPage extends Component {

  // componentDidMount = () => {
  //   localStorage.clear()
  // }

  render() {
    return (
      <>
        <BannerComponent />
        <div className="mg-top-20 mg-bttm-20">

          <div className="container-round-border mg-top-20 mg-left-50 mg-right-50" style={{ padding: '20px', height: '100%' }}>
            <Row type="flex" justify="center">
              <Col>
                <span className="form-title-red txt-center">ການລົງທະບຽນຂອງທ່ານສຳເລັດແລ້ວ.</span>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col>
                <span className="form-title-red txt-center">Your registration is completed.</span>
              </Col>
            </Row>
            <Row type="flex" justify="center" className="mg-top-20">
              <span className="txt-header txt-center">ຊຳລະເງິນສົດ</span>
            </Row>
            <Row type="flex" justify="center">
              <span className="txt-header txt-center">Kanki Shop ສິນຄ້າຍີ່ປຸ່ນ</span>
            </Row>
            <Row type="flex" align="middle" justify="center">
              <span className="txt-header">
                ບ້ານຮ່ອງໄກ່ແກ້ວ ຕໍ່ໜ້ວຍຫວຍກິລາເກົ່າ (ແຄມທາງໃຫຍ່)  , ເມືອງຈັນທະບູລີ ນະຄອນຫລວງວຽງຈັນ
              </span>
            </Row>
            <Row type="flex" align="middle" justify="center">
              <span className="txt-header">
                ເປີດທຸກມື້ 9:00~19:30
              </span>
            </Row>
            <Row type="flex" justify="center">
              <span className="txt-header txt-details txt-center mg-top-10 mg-bottom-10">ສາມາດກົດເບິ່ງແຜນທີ່ດ້ານລຸ່ມ / Click the image below to Open Map</span>
            </Row>
          </div>
        </div>
      </>
    )
  }
}

export default connect(null)(SuccessPage)