import * as actionTypes from './../../../constants/actionTypes'

const initialState = {
  searchvalue: {}
  // searchvalue: { "id": 25769803792, "created_at": "2019-11-20T05:27:57Z", "updated_at": "2019-11-20T05:27:57Z", "team_name": "jvongxay", "code": "BFH732IGLTYPFB5E", "email": "jvongxay@laoitdev.com", "phone": "2077805085", "emergency_contact": "2077805085", "agreement": true, "status": "PENDING_PAYMENT", "attendee": { "id": 17, "created_at": "2019-11-20T05:27:57Z", "updated_at": "2019-11-20T05:28:05Z", "first_name": "JALANA", "last_name": "VONGXAY", "gender": "MALE", "birth_date": "1998-04-18T00:00:00Z", "identify": "PA01234567", "email": "jvongxay@laoitdev.com", "phone": "2077805085", "address": "Nasangphai", "city": "Saysettha", "province": "Vientiane", "country": "Lao", "nationality": "Lao", "shirt_size": { "id": 47244640257, "created_at": "2019-11-13T16:04:51Z", "updated_at": "2019-11-13T16:04:51Z", "name": "M", "description": "xxx" }, "blood_type": { "id": 21474836482, "created_at": "2019-11-13T16:04:51Z", "updated_at": "2019-11-13T16:04:51Z", "name": "O+" }, "sub_class": { "id": 51539607552, "created_at": "2019-11-14T00:00:00Z", "updated_at": "2019-11-14T00:00:00Z", "name": "A", "description": "xxx" }, "class": { "id": 8589934592, "name": "A Class", "description": "xxx" }, "distance": { "id": 12884901889, "created_at": "2019-11-13T16:32:50Z", "updated_at": "2019-11-13T16:32:50Z", "distance": 7, "limit": 250 }, "bike_price": { "id": 17179869185, "created_at": "2019-11-14T00:00:00Z", "updated_at": "2019-11-14T00:00:00Z", "name": "Normal", "price": 150000, "start_at": "2019-11-16T00:00:00Z", "end_at": "2019-11-30T00:00:00Z" } } }
}

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SEARCH:
      state = Object.assign({}, state, { searchvalue: action.payload })
      return state
    default:
      return state
  }
}