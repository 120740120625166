import React, { Component } from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Divider } from 'antd';
import Image from 'react-image-webp'
import VangViengDesktop from './../assets/images/banner/VangViengCoverDesktop.png'
import VangViengWebpDesktop from './../assets/images/banner/VangViengCoverDesktop.webp'
import VangViengMobile from './../assets/images/banner/VangViengCoverMobile.png'
import VangViengWebpMobile from './../assets/images/banner/VangViengCoverMobile.webp'
import { isMobile } from 'react-device-detect';

class BannerComponent extends Component {
  render() {
    return (
      <React.Fragment>
        <Row >
          {isMobile && (
            <Image
              src={VangViengMobile}
              webp={VangViengWebpMobile}
              alt="Run for School"
              style={{ width: '100%', height: 'auto' }}
            />
          )}
          {!isMobile && (
            <Image
              src={VangViengDesktop}
              webp={VangViengWebpDesktop}
              alt="Run for School"
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </Row>

        <Row>
          <Col span={4}></Col>
          <Col xs={16}><Divider /></Col>
          <Col span={4}></Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default BannerComponent