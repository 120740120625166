import React, { Component } from 'react'
import { Row, Col } from 'antd'

import Image from 'react-image-webp'

import notfoundwebp from './../../../assets/images/404.webp'

class Err404Page extends Component {
  render() {
    return (
      <>
        <div className="mg-left-50 mg-right-50 mg-top-20">
          <Row type="flex" justify="space-between" align="top" style={{ textAlign: "center", alignItems: "center" }}>
            <Col lg={8} order={1}>
              <Image
                src={notfoundwebp}
                webp={notfoundwebp}
                alt="gif to https://giphy.com/gifs/gustavo-art-kidmograph-gustavo-YyKPbc5OOTSQE"
              />
            </Col>
            <Col lg={16} order={2}>
              <span style={{ fontSize: "5vw" }}> not found!</span>
            </Col>
          </Row>
          <Row type="flex" align="middle" justify="end" style={{ textAlign: "center", marginTop: '-15%' }}>
            <Col lg={8} order={1}>
              <span style={{ fontSize: "5vw" }}>not found!</span>
            </Col>
            <Col lg={16} order={2}>
              <Image
                src={notfoundwebp}
                webp={notfoundwebp}
                alt="gif to https://giphy.com/gifs/gustavo-art-kidmograph-gustavo-YyKPbc5OOTSQE"
              />
            </Col>
          </Row>

        </div>
      </>
    )
  }
}
export default Err404Page