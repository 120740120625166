import * as actionTypes from './../../../constants/actionTypes'

export const register = (formvalue) => ({
  type: actionTypes.REGISTRATION,
  payload: formvalue
})

export const savePanes = (panes) => ({
  type: actionTypes.SAVE_PANES,
  payload: panes
})

export const enableNext = (enable) => ({
  type: actionTypes.ENABLENEXT,
  payload: enable
})

export const changeCurrent = (current) => ({
  type: actionTypes.CHANGE_AFTERSUBMIT,
  payload: current
})

export const saveRaceCate = (raceCate) => ({
  type: actionTypes.SAVERACECATE,
  payload: raceCate
})