import React, { Component, Fragment } from "react";
import { Card, Row, Col, Table, Modal, Button, notification } from "antd";
import AddUser from "./AddUser";
import { axiosInstant } from "./../../../../service/axios";
import EditUser from './EditUser';
import ChangePassword from "./changePassword";
const { confirm } = Modal;

class Users extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      editVisible: false,
      changePasswordVisible: false,
      userData: null,

    };
  }
  fetchData = url => {
    axiosInstant
      .get(url)
      .then(({ data: { users } }) => {
        this.setState({ items: users });
      })
      .catch(err => {
        // TODO: fixxx
      });
  };
  componentDidMount() {
    this.fetchData("/users");
  }
  handleAddUserSubmit = ({ firstname, lastname, username, password, role }) => {
    axiosInstant
      .post("/users/register", {
        first_name: firstname,
        last_name: lastname,
        username,
        password,
        role
      })
      .then(({ data: { user } }) => {
        const newItems = [user, ...this.state.items];
        this.setState({ items: newItems });
      })
      .catch(error => { });
  };
  handleUpdate = ({ id, first_name, last_name }) => {
    if (localStorage.getItem("id") === id) {
      axiosInstant
        .put(`/users/me/profile`, {
          first_name: first_name,
          last_name: last_name,
        })
        .then((res) => {
          if (res.status === 204) {
            const newItems = [...this.state.items];
            newItems.forEach(item => {
              if (item.id === id) {
                item.first_name = first_name;
                item.last_name = last_name;
              }
            });
            this.setState({
              items: newItems,
              editVisible: false,
              changePasswordVisible: false,
            });
            this.openNotificationWithIcon("success", "ແກ້ໄຂຂໍ້ມູນສຳເລັດ");
          }
        })
        .catch(err => { });
    } else {
      axiosInstant
        .put(`/users/profile`, {
          id: id,
          first_name: first_name,
          last_name: last_name,
        })
        .then((res) => {
          if (res.status === 204) {
            const newItems = [...this.state.items];
            newItems.forEach(item => {
              if (item.id === id) {
                item.first_name = first_name;
                item.last_name = last_name;
              }
            });
            this.setState({
              items: newItems,
              editVisible: false,
              changePasswordVisible: false,
            });
            this.openNotificationWithIcon("success", "ແກ້ໄຂຂໍ້ມູນສຳເລັດ");
          }
        })
        .catch(err => { });
    }

  };

  openNotificationWithIcon = (type, messages) => {
    notification[type]({
      message: "ການແກ້ໄຂ",
      description: messages
    });
  };

  handleChangePassword = ({ id, password }) => {

    if (localStorage.getItem("id") === id) {
      axiosInstant.post("/users/me/change-password",
        { new_password: password })
        .then((res) => {
          if (res.status === 204) {
            this.setState({ changePasswordVisible: false })
            this.openNotificationWithIcon("success", "ປ່ຽນລະຫັດຜ່ານສຳເລັດ");
          }
        }).catch(err => {

        })
    }
    else {
      axiosInstant.post("/users/change-password",
        { id: id, new_password: password })
        .then((res) => {
          if (res.status === 204) {
            this.setState({ changePasswordVisible: false })
            this.openNotificationWithIcon("success", "ປ່ຽນລະຫັດຜ່ານສຳເລັດ");
          }
        }).catch(err => {

        })
    }
  }

  showConfirm = (e, message, id, status) => {
    e.preventDefault();
    confirm({
      title: message,
      okText: "ຕົກລົງ",
      cancelText: "ບໍ່ຕົກລົງ",
      style: { fontFamily: "Phetsarath OT" },
      onOk: () => {
        switch (status) {
          case false:
            axiosInstant
              .post(`/users/enable`, { id: id })
              .then(res => {
                if (res.status === 204) {
                  this.openNotificationWithIcon("success", "ການເປີດໃຊ້ງານສຳເລັດແລ້ວ");
                  const newItems = [...this.state.items];
                  newItems.forEach(item => {
                    if (item.id === id) {
                      item.enable = true;
                    }
                  });
                  this.setState({
                    items: newItems,
                    editVisible: false,
                    changePasswordVisible: false,
                  });
                }
              })
              .catch(error => {
                this.openNotificationWithIcon(
                  "error",
                  "ບໍ່ສາມາດເປິດໃຊ້ງານໄດ້"
                );
              });

            break;
          default:
            axiosInstant
              .post(`/users/disable`, { id: id })
              .then(res => {
                if (res.status === 204) {
                  this.openNotificationWithIcon("success", "ການປິດໃຊ້ງານສຳເລັດແລ້ວ");
                  const newItems = [...this.state.items];
                  newItems.forEach(item => {
                    if (item.id === id) {
                      item.enable = false;
                    }
                  });
                  this.setState({
                    items: newItems,
                    editVisible: false,
                    changePasswordVisible: false,
                  });
                }
              })
              .catch(error => {
                this.openNotificationWithIcon(
                  "error",
                  "ບໍ່ສາມາດປິດໃຊ້ງານໄດ້"
                );
              });

        }
      },
      onCancel() { }
    });
  };

  showModal = (e, userData, type) => {
    e.preventDefault();
    switch (type) {
      case "edit":
        this.setState({
          editVisible: true,
          userData: userData
        });
        break;
      default:
        this.setState({
          changePasswordVisible: true,
          userData: userData
        });
    }
  };
  handleCancel = () => {
    this.setState({
      editVisible: false,
      changePasswordVisible: false,
    });
  };
  render() {
    const columns = [
      {
        title: "ຊື່ ແລະ ນາມສະກຸນ",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "ຊື່ຜູ່ໃຊ້ງານ",
        dataIndex: "username",
        key: "username"
      },
      {
        title: "ສິດທິຜູ້ໃຊ້",
        dataIndex: "role",
        key: "role"
      },
      {
        title: "ສະຖານະຜູ່ໃຊ້ງານ",
        dataIndex: "status",
        key: "status"
      },
      {
        title: "ຕັ້ງຄ່າ",
        dataIndex: "setting",
        key: "setting",
        render: item => {
          return (
            <>
              {
                localStorage.getItem("id") === item.id ||
                  item.role === "guest" ||
                  item.role === "organizer"
                  ? <>
                    <span>
                      <Button type="link" href="#" onClick={e => this.showModal(e, item, "edit")}>
                        ແກ້ໄຂ
                      </Button>
                    </span>
                    <span>
                      <Button type="link" href="#" onClick={e => this.showModal(e, item, "change_pass")}>
                        ປ່ຽນລະຫັດຜ່ານ
                      </Button>
                    </span>
                  </> : ""
              }
              {
                item.role !== "admin" ?
                  <span>
                    <Button type="link" href="#"
                      onClick={e => this.showConfirm(e, item.enable ? "ປິດໃຊ້ງານຜູ້ໃຊ້ ??" : "ເປີດໃຊ້ງານຜູ້ໃຊ້", item.id, item.enable)}
                    >
                      {item.enable ? "ປິດໃຊ້ງານ" : "ເປີດໃຊ້ງານ"}
                    </Button>
                  </span>
                  : ""
              }
            </>
          )
        }
      }
    ];
    return (
      <Fragment>
        <div className="gutter-example">
          <Row gutter={24}>
            <Col className="gutter-row" md={24} style={{ marginBottom: 20 }}>
              <Card title="ລາຍຊື່ຜູ່ໃຊ້ງານ" bordered={false}>
                <Table
                  columns={columns}
                  dataSource={this.state.items.map((item, index) => {
                    return {
                      key: index + 1,
                      name: item.first_name + " " + item.last_name,
                      username: item.username,
                      role: item.role,
                      status: item.enable ? "enable" : "disabled",
                      setting: item
                    };
                  })}
                />
              </Card>
            </Col>
            <Col className="gutter-row" md={12}>
              <Card title="ເພີ່ມຜູ່ໃຊ້ງານ" bordered={false}>
                <AddUser handleAddUserSubmit={this.handleAddUserSubmit} />
              </Card>
            </Col>
          </Row>
        </div>
        {this.showModal && (
          <>
            <Modal
              width="568px"
              visible={this.state.editVisible}
              title="ແກ້ໄຂ"
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              style={{ fontFamily: "Phetsarath OT" }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <EditUser handleUpdate={this.handleUpdate} userData={this.state.userData} />
            </Modal>
            <Modal
              width="568px"
              visible={this.state.changePasswordVisible}
              title="ປ່ຽນລະຫັດຜ່ານ"
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              style={{ fontFamily: "Phetsarath OT" }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <ChangePassword handleChangePassword={this.handleChangePassword} userData={this.state.userData} />
            </Modal>
          </>
        )}
      </Fragment>
    );
  }
}
export default Users;
