import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import Image from 'react-image-webp'

import BannerComponent from '../../../components/banner'
import PowerBy from '../../../components/powerby'

import constructjpg from './../../../assets/images/underconstruct.jpg'
import constructwebp from './../../../assets/images/underconstruct.webp'



class InDevelopment extends Component {
  render() {
    return (
      <div>
        <div className="container-round-border" style={{ padding: isMobile ? "20px" : "50px", textAlign: 'center' }}>

          <Image
            src={constructjpg}
            webp={constructwebp}
            alt="KUANJAI"
            style={{ width: '500px', height: '350px' }}
          />

        </div>
      </div>
    )
  }
}

export default InDevelopment