import Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

const checkStatus = status => {
  let statusName = "";
  switch (status) {
    case "Pending":
      statusName = "ລໍຖ້າຈ່າຍເງິນ";
      break;
    case "Success":
      statusName = "ຈ່າຍເງິນສຳເລັດ";
      break;
    case "Denied":
      statusName = "ລໍຖ້າເອົາເຄື່ອງ";
      break;
    case "Complete":
      statusName = "ສຳເລັດແລ້ວ";
      break;
    case "Cancel":
      statusName = "ຍົກເລີກ";
      break;
    default:
      break;
  }
  return statusName;
};


export const exportExcelReportAll = items => {
  var workbook = new Excel.Workbook();
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();
  var worksheet = workbook.addWorksheet("Publications");
  worksheet.mergeCells("A1", "K1");
  worksheet.getCell("A1").value = "ລາຍງານການລົງທະບຽນ";
  worksheet.getRow(2).values = [
    "ວັນທີລົງທະບຽນ",
    "ລະຫັດລົງທະບຽນ",
    "ຊື່ ແລະ ນາມສະກຸນ",
    "ເພດ",
    "ເບີໂທ",
    "ອີເມວ",
    "ລາຄາ",
    "ປະເພດບັດ",
    "ຈຳນວນບັດ",
    "ສະຖານະການຈ່າຍ"
  ];
  worksheet.columns = [
    { key: "datetime", width: 15 },
    { key: "code", width: 25 },
    { key: "fullname" },
    { key: "gender" },
    { key: "phone" },
    { key: "email" },
    { key: "price" },
    { key: "distance" },
    { key: "quantity" },
    { key: "status", width: 12 }
  ];
  var firstRow = worksheet.getRow(1);
  firstRow.alignment = { vertical: "middle", horizontal: "center" };
  firstRow.height = 20;
  firstRow.font = { name: "Phetsarath OT", family: 4, size: 16, bold: true };

  var secondRow = worksheet.getRow(2);
  secondRow.font = { name: "Phetsarath OT", family: 4, size: 10, bold: true };
  secondRow.alignment = { vertical: "middle", horizontal: "center" };
  secondRow.height = 20;
  let count = 3, code = "";
  items.forEach(item => {
    console.log(item);
    worksheet.addRow({
      datetime: moment(item.created_at).format("DD/MM/YYYY H:mm:s"),
      code: item.id,
      fullname: item.attendee.first_name + " " + item.attendee.last_name,
      gender: item.attendee.gender === "MALE" ? "ຊາຍ" : "ຍິງ",
      phone: item.attendee.phone,
      email: item.attendee.email,
      distance: item.ticket.name,
      price: item.ticket.price,
      quantity: item.quantity,
      status: checkStatus(item.status)
    });
    if (code !== item.id) {
      code = item.id
    } else {
      const mergeCount = count - 1
      worksheet.mergeCells(`B${mergeCount}`, `B${count}`)
      worksheet.mergeCells(`A${mergeCount}`, `A${count}`)
      worksheet.mergeCells(`P${mergeCount}`, `P${count}`)
      worksheet.getCell(`B${mergeCount}`).alignment = { vertical: "middle" }
      worksheet.getCell(`A${mergeCount}`).alignment = { vertical: "middle" }
      worksheet.getCell(`P${mergeCount}`).alignment = { vertical: "middle" }
    }
    worksheet.getRow(count++).font = {
      name: "Phetsarath OT",
      family: 4,
      size: 12
    };
  });
  workbook.xlsx.writeBuffer().then(function (data) {
    var blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(
      blob,
      `ລາຍງານການລົງທະບຽນ ${moment(new Date()).format("DD/MM/YYYY H:mm:s")}.xlsx`
    );
  });
}; 
