import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Icon, Input, DatePicker, Select, InputNumber, Slider, Button } from 'antd'
import moment from 'moment'
// import data
import { isMobile } from 'react-device-detect'

const FormItem = Form.Item
const { Option } = Select



export const CreateMainForm = ({
  firstnameErr,
  lastnameErr,
  genderErr,
  emailErr,
  phoneErr,
  quantityErr,
  quantity,
  form,
  onGenderChange,
  onTicketChange,
}) => {

  const marks = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10
  }

  const [ticket, setTicket] = useState(quantity === undefined ? 1 : quantity)

  return (
    <div>
      <div className="mg-bottom-20">
        <Row type="flex" justify="center">
          <Col>
            <span className="form-title-red txt-center">ຂໍ້ມູນຜູ້ເຂົ້າຮ່ວມງານ Attendee info</span>
          </Col>
        </Row>
      </div>
      <div className="container-round-border" style={{ padding: isMobile ? "10px" : "50px" }}>
        <Row type="flex" gutter={46}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25">
            <Row>ຊື່ Firstname <span style={{ color: "red" }}>*(ບໍ່ໃສ່ຄຳນຳໜ້າ Without Prefix.)</span></Row>
            <Row>
              <FormItem validateStatus={firstnameErr ? 'error' : ''} help={firstnameErr || ''} >
                {form.getFieldDecorator(`firstname`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true, message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ຊື່.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your firstname.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input placeholder="ປ້ອນຊື່ Firstname" />
                )}
              </FormItem>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25">
            <Row>ນາມສະກຸນ Lastname <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={lastnameErr ? 'error' : ''} help={lastnameErr || ''} >
                {form.getFieldDecorator(`lastname`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true, message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ນາມສະກຸນ.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your lastname.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input placeholder="ປ້ອນນາມສະກຸນ Lastname" />
                )}
              </FormItem>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25">
            <Row>ເພດ Gender <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={genderErr ? 'error' : ''} help={genderErr || ''} >
                {form.getFieldDecorator(`gender`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col>
                          <span className="form-label-15 spn-red">
                            <Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" />
                          </span>
                        </Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາເລືອກເພດ.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please selectgender.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Select key={0}
                    placeholder="ເລືອກເພດ Choose Gender"
                    onChange={e => { onGenderChange(e) }}
                    className="form-label-15"
                    style={{ width: '100%' }}
                  >
                    <Option className="form-label-15" value="MALE">Male / ຊາຍ</Option>
                    <Option className="form-label-15" value="FEMALE">Female / ຍິງ</Option>
                  </Select>
                )}
              </FormItem>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25">
            <Row>ເບີໂທ Phone <span style={{ color: "red" }}>* (ກະລຸນາໃສ່ເບີໂທທີ່ສາມາດຕິດຕໍ່ໄດ້)</span></Row>
            <Row>
              <FormItem validateStatus={phoneErr ? 'error' : ''} help={phoneErr || ''} >
                {form.getFieldDecorator(`phone`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ເບີໂທຕິດຕໍ່.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your Contact Number.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input placeholder="20xxxxxxxx " max={14} min={8} />
                )}
              </FormItem>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25">
            <Row>ອີເມລ Email <span style={{ color: "red" }}> (ໃຊ້ເພຶ່ອຮັບໃບບິນຮັບເງິນ)</span></Row>
            <Row>
              <FormItem validateStatus={emailErr ? 'error' : ''} help={emailErr || ''} >
                {form.getFieldDecorator(`email`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  // pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  rules: [{
                    required: false,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ອີເວລ.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your Email.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input placeholder="ປ້ອນອີເມວ E-mail (ຖ້າມີ)" />
                )}
              </FormItem>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}
            className="form-label-25 mg-top-10"
          >
            <Row >
              <span style={{ fontWeight: "bold" }}>ຈຳນວນບັດ Ticket : {ticket} ບັດ</span>
            </Row>
            <Row >
              <FormItem validateStatus={quantityErr ? 'error' : ''} help={quantityErr || ''} >
                {form.getFieldDecorator(`quantity`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ເບີໂທຕິດຕໍ່.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your Contact Number.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <InputNumber type="number" min={1} />
                )}
              </FormItem>
              {/* <Col xs={4} sm={4} md={4} lg={4} xl={4}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Button
                  onClick={() => {
                    setTicket(ticket > 1 ? ticket - 1 : 1);
                    onTicketChange(ticket > 1 ? ticket - 1 : 1)
                  }}
                  className="buton-color"
                >
                  -
                </Button>
              </Col >
              <Col
                xs={16} sm={16} md={16} lg={16} xl={16}
              >
                <Slider
                  onChange={e => { setTicket(e); onTicketChange(e) }}
                  value={ticket}
                  marks={marks}
                  defaultValue={ticket}
                  min={1}
                  max={10}
                />
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Button
                  onClick={() => {
                    setTicket(ticket < 10 ? ticket + 1 : 10);
                    onTicketChange(ticket < 10 ? ticket + 1 : 10)
                  }}
                  className="buton-color"
                >
                  +
                      </Button>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>

    </div >
  )
}