import React, { Component } from "react";
import { axiosInstant } from "../../../../service/axios";
import { Card, Table } from "antd";
// import logo from "./../../../assets/images/BFH_2020.png";
import moment from "moment";
import Barcode from "react-barcode";
class RegistrationDetail extends Component {
  state = {
    loading: false,
    attendee: {}
  };
  componentDidMount() {
    // axiosInstant
    //   .get(`/books/${this.props.item.code}/attendee`)
    //   .then(res => {
    //     this.setState({ loading: false, attendee: res.data });
    //   })
    //   .catch(error => { });
    // this.setState({ attendee: this.props.item.action })
    // console.log(this.props.item);
    // console.log(this.props.item);
  }
  fetchData = () => {
    const { attendee } = this.props.item.action;
    return [
      {
        key: attendee.id,
        gender: attendee.gender,
      }
    ];
  };

  render() {
    const { item } = this.props;
    // const { bike_price } = this.props.item;
    // const { attendee } = this.state;
    // const columns = [
    //   {
    //     title: "ເພດ",
    //     dataIndex: "gender",
    //     key: "gender",
    //   },
    //   {
    //     title: "ວັນເດືອນປີເກີດ",
    //     dataIndex: "birth_date",
    //     key: "birth_date"
    //   },
    //   {
    //     title: "ສັນຊາດ",
    //     dataIndex: "nationality",
    //     key: "nationality"
    //   },
    //   {
    //     title: "ບ້ານ",
    //     dataIndex: "address",
    //     key: "address"
    //   },
    //   {
    //     title: "ເມືອງ",
    //     dataIndex: "city",
    //     key: "city"
    //   },
    //   {
    //     title: "ແຂວງ​",
    //     dataIndex: "province",
    //     key: "province"
    //   },
    //   {
    //     title: "ປະເທດ",
    //     dataIndex: "country",
    //     key: "country"
    //   },
    //   // {
    //   //   title: "ເບີຕິດຕໍ່ສຸກເຊີນ",
    //   //   dataIndex: "emergency_contact",
    //   //   key: "emergency_contact"
    //   // },
    // ];
    return (

      <>
        {!this.state.loading && (
          <>
            <div style={{ textAlign: "center", paddingBottom: "5px" }}>
              {/* <img src={logo} width="200px" /> */}
            </div>
            <Card className="card-bill" style={{ width: "100%" }}>
              <div>
                <div className="float-right">
                  <a >
                    <Barcode
                      displayValue={true}
                      value={item.code}
                      height={60}
                      width={1}
                    />
                  </a>
                </div>
                <div style={{ paddingTop: "5px", fontSize: '12pt' }}>
                  ຊື່ ແລະ ນາມສະກຸນ : {item.full_name} <br /><br />
                  ເພດ : {item.gender} <br /><br />
                  ອີເມວ : {item.email} <br /><br />
                  ເບີໂທ : {item.phone} <br /><br />
                  ວັນທີລົງທະບຽນ : {item.created_at} <br /><br />
                  ລາຄາ: {item.price} ກີບ
                  <br /><br />
                  ປະເພດບັດ : {item.distance} <br /><br />

                  ຈຳນວນບັດ : {item.quantity}

                </div>
              </div>
            </Card>
            {/* <h3 className="text-center" style={{ padding: "10px" }}>
              <u>ຂໍ້ມູນ</u>
            </h3>
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={this.fetchData()}
                pagination={false}
                bordered={true}
              />
            </div> */}
          </>
        )
        }
      </>
    );
  }
}

export default RegistrationDetail;
