import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Form, Checkbox, notification, Icon, Tabs, message } from 'antd'
import moment, { ISO_8601 } from 'moment'

// import axios from 'axios'

import { axiosInstant } from './../../../service/axios'

import BannerComponent from './../../components/banner';

// import form 
import { CreateMainForm } from './components/mainregis'

// import action
import { register, enableNext, changeCurrent, savePanes } from './actions'
import { isMobile } from 'react-device-detect'
import history from '../../../history'

const MainRegis = ({
  firstnameErr,
  lastnameErr,
  genderErr,
  emailErr,
  phoneErr,
  quantityErr,
  quantity,
  form,
  onGenderChange,
  onTicketChange
}) => {
  return <CreateMainForm
    firstnameErr={firstnameErr}
    lastnameErr={lastnameErr}
    genderErr={genderErr}
    emailErr={emailErr}
    phoneErr={phoneErr}
    quantityErr={quantityErr}
    quantity={quantity}
    form={form}
    onGenderChange={onGenderChange}
    onTicketChange={onTicketChange}
  />
}

class FormPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      agreement: false,
      formvalue: {
        attendee: [
          {
            first_name: "",
            last_name: "",
            gender: "",
            email: "",
            phone: "",
          }
        ],
        quantity: 1,
        ticket: { id: "" }

      },
      showbutton: false,
      isLoading: false,
      tickets: [],
      qty: 1,
      enableNext: false
    }
  }

  onChange = value => {
    this.setState({ activeKey: value, loading: true })
  }

  onGenderChange = (e) => {
    this.setState({
      attendeeGender: e
    })
  }

  onTicketChange = (e) => {
    this.setState({
      qty: parseInt(e)
    })
  }

  checkedbox = e => {
    // const { formvalue } = this.props.regis
    if (e.target.checked === true) {
      this.setState({
        agreement: e.target.checked,
        current: 1,
        showbutton: true,
      })
    } else {
      this.setState({
        agreement: e.target.checked,
        current: 0,
        showbutton: false,
      })
    }
  }

  formSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (!err) {
        // rearrange data format to match the api
        const { formvalue } = this.state
        const key = Object.keys(fieldsValue)
        const index = []
        key.shift()
        key.forEach(element => {
          const lastChar = element[element.length - 1]
          index.push(parseInt(lastChar))
        })

        this.setState(Object.assign(formvalue, {
          attendee:
          {
            first_name: fieldsValue.firstname.replace(/\s/g, ''),
            last_name: fieldsValue.lastname.replace(/\s/g, ''),
            gender: fieldsValue.gender,
            email: fieldsValue.email,
            phone: fieldsValue.phone
          },
          quantity: parseInt(fieldsValue.quantity),
          // quantity: parseInt(this.state.qty),
          ticket: {
            id: this.state.tickets[0].id
          }

        }))

        this.setState({
          enableNext: true,
          current: 1
        })
        this.props.changeCurrent(1)
        this.props.enableNext(true)

        notification.success({
          message: "ບັນທຶກສຳເລັດ! Save successful!",
          description: "Your data have been save! ຂໍ້ມູນຂອງທ່ານໄດ້ຖືກບັນທຶກແລ້ວ",
          style: {
            backgroundColor: '#ffffffff',
            border: 'solid 1px green'
          },
          duration: 3
        })
      } else {
        notification.warning({
          message: <span className="txt-alert">ເກີດຂໍ້ຜິດພາດ! Error!</span>,
          description: <span className="txt-alert">Please Fill in the form completely! ກະລຸນາຕື່ມຂໍ້ມູນໃນຟອມໃຫ້ສຳເລັດກ່ອນ</span>,
          style: {
            backgroundColor: '#ffffffff',
            border: 'solid 1px red'
          },
          duration: 3
        })
      }
      this.props.register(this.state.formvalue)
    })
  }

  checkattendee = () => {
    history.push({
      pathname: '/checkattendee'
    })
  }

  componentDidMount = () => {
    var t = "w";

    console.log(localStorage.getItem("test"));

    localStorage.setItem("test", "1234");

    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return localStorage.removeItem("test");
    });
    window.addEventListener("unload", (ev) => {
      ev.preventDefault();
      return t = ev;
    });



    Promise.all([axiosInstant.get(`/tickets?valid-only=${true}`)]
    ).then(([{ data: { tickets } }]) => {

      this.setState({
        tickets,
        isLoading: true
      })

      const { setFieldsValue } = this.props.form
      const { attendee, quantity } = this.props.regis.formvalue
      if (attendee.first_name !== "") {
        setFieldsValue({
          ["firstname"]: attendee.first_name,
          ["lastname"]: attendee.last_name,
          ["gender"]: attendee.gender,
          ["email"]: attendee.email,
          ["phone"]: attendee.phone,
          ["quantity"]: quantity
        })
      }
      else {
        setFieldsValue({
          ["quantity"]: quantity
        })
      }
    }).catch(error => {

    })

  }

  componentWillUnmount = () => {
  }

  render() {
    const { getFieldDecorator, getFieldError, isFieldTouched } = this.props.form;

    const firstnameErr = isFieldTouched(`firstname`) && getFieldError(`firstname`);
    const lastnameErr = isFieldTouched(`lastname`) && getFieldError(`lastname`);
    const genderErr = isFieldTouched(`gender`) && getFieldError(`gender`);
    const emailErr = isFieldTouched(`email`) && getFieldError(`email`);
    const phoneErr = isFieldTouched(`phone`) && getFieldError(`phone`);
    const quantityErr = isFieldTouched(`quantity`) && getFieldError(`quantity`);

    // User agreement
    const AgreeErr = isFieldTouched('Agreement') && getFieldError('Agreement')
    const { agreement, isLoading } = this.state
    const { attendee } = this.props.regis.formvalue
    let color
    (agreement === true) ? color = "green" : color = "red"
    return (
      <>
        {isLoading && <div style={{ overflowY: "hidden" }}>
          <BannerComponent />

          <div className="mg-top-20 ">
            <Row type="flex" justify="center">
              <Col>
                <Button
                  onClick={this.checkattendee}
                  style={{ borderRadius: '5px', color: '#9DB3C7', backgroundColor: 'red', width: 'auto', height: '45px' }} size="large">
                  <Row type="flex" align="middle" gutter={10}>
                    <Col>
                      <Icon style={{ color: '#ffffff' }} type="search" />
                    </Col>
                    <Col>
                      <span className="form-label-10 white-txt">ກວດສອບລາຍການລົງທະບຽນ</span>
                    </Col>
                  </Row>
                </Button>
              </Col>
            </Row>
          </div>

          <Row type="flex"
            className={!isMobile ? "mg-left-50 mg-right-50 mg-top-20" :
              "mg-left-20 mg-right-20 mg-top-10"}
          >
            <Form style={{ width: '100%' }} onSubmit={this.formSubmit}>
              <MainRegis
                firstnameErr={firstnameErr}
                lastnameErr={lastnameErr}
                genderErr={genderErr}
                emailErr={emailErr}
                phoneErr={phoneErr}
                quantityErr={quantityErr}
                quantity={attendee.quantity}
                form={this.props.form}
                onGenderChange={e => { this.onGenderChange(e) }}
                onTicketChange={e => { this.onTicketChange(e) }}
              />

              <div className="mg-top-20 mg-bttm-20"></div>
              <div className="container">
                <Row type="flex" gutter={12}>
                  <Col>
                    <Form.Item validateStatus={AgreeErr ? 'error' : ''} help={AgreeErr || ''}>
                      {getFieldDecorator('Agreement', {
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{ required: true }],
                        valuePropName: 'checked',
                      })(
                        <Checkbox onChange={this.checkedbox}></Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Row type="flex">
                      <span className="form-label-25" style={{ color: color }}>
                        ຂ້າພະເຈົ້າໄດ້ອ່ານ, ເຂົ້າໃຈ ແລະຍິນດີປະຕິບັດຕາມກົດຂອງການເຂົ້າຮ່ວມງານ.
                        </span>
                    </Row>
                    <Row type="flex">
                      <span className="form-label-25" style={{ color: color }}>
                        I fully read, understand, and abide to Code of Conduct .
                        </span>
                    </Row>
                  </Col>
                </Row>
              </div>
              {
                this.state.showbutton &&
                <div className="mg-top-20">
                  <Row type="flex" justify="center" align="middle">
                    <Button style={{ backgroundColor: '#ED6E26', width: 'auto', height: '50px', borderRadius: '10px' }} htmlType="submit"><span className="white-txt txt-bold txt-submit-btn">ບັນທຶກຂໍ້ມູນ / Save</span></Button>
                  </Row>
                </div>
              }
            </Form>
          </Row>
        </div>}
      </>
    )
  }
}

const mapDispatchToProps = {
  register,
  enableNext,
  changeCurrent,
  savePanes
}

const mapStateToProps = (state) => ({
  regis: state.register
})

const FormcomponentWrap = Form.create()(FormPage);

export default connect(mapStateToProps, mapDispatchToProps)(FormcomponentWrap)