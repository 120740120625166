import React, { Component } from 'react';

import { Row, Col } from 'antd'
import Image from 'react-image-webp'
import litd_logo from './../assets/images/banner/footer.png'
import litd_logo_webp from './../assets/images/banner/footer.webp'

import { isMobile } from 'react-device-detect';

class PowerBy extends Component {

  render() {
    return (
      <React.Fragment>

        <div>
          <Row type="flex" justify="center" className="mg-top-20">
            <Col span={24}>
              <Row type="flex" justify="center">
                <span className="form-title-red txt-center">ຕິດຕໍ່</span>
              </Row>
              <Row type="flex" justify="center">
                <span className="form-title-red txt-center">Contact</span>
              </Row>

              <Row type="flex" justify="center" className="mg-top-20">
                <span className="txt-header txt-center"><b>Whatsapp</b>: <a href="https://wa.me/8562056634404" target="_blank">020 56634404</a> </span>
              </Row>

              <Row type="flex" justify="center">
                <span
                  className="txt-header txt-center"
                >
                  <b>Facebook Page</b>: <a href="https://www.facebook.com/vangviengfest" target="_blank">VangVieng Music Fest ວັງວຽງ</a>
                </span>
              </Row>

              {/* <Row type="flex" justify="center" className="mg-top-20">
                <span className="form-label-15 txt-center"><b>E-mail</b>: info@bikeforher.la</span>
              </Row>
              <Row type="flex" justify="center">
                <span className="form-label-15 txt-center"><b>Call</b>: +856 (21) 216666</span>
              </Row>
              <Row type="flex" justify="center">
                <span className="form-label-15 txt-center"><b>Call</b>: +856 (20) 28243086 (Tula)</span>
              </Row>
              <Row type="flex" justify="center">
                <span className="form-label-15 txt-center"><b>Call</b>: +856 (20) 55288555 (Aeck)</span>
              </Row>
              <Row type="flex" justify="center">
                <span className="form-label-15 txt-center"><b>Call</b>: +856 (20) 28042166 (Jenly)</span>
              </Row>
              <Row type="flex" justify="center">
                <span className="form-label-15 txt-center"><b>Call</b>: +856 (20) 99887717 (Paeng)</span>
              </Row> */}
            </Col>
          </Row>
        </div>

        <div className="hiddenoverflow">
          <Row type="flex" justify="center" align="middle">
            <span
              className="form-label-10"
            >
              Copyright © <a href="https://laoitdev.com/" target="_blank">LAOITDEV</a>
            </span>
          </Row>

        </div>

        <div className="mg-top-20 hiddenoverflow">

          <Row type="flex" gutter={12} align="middle" justify="center">
            {/* <Col><span className="form-label-15">Powered By </span></Col> */}
            <Col>
              {/* <img src={litd_logo} alt="Lao IT Development Co., Ltd." width="90" height="120" /> */}

              <Image
                src={litd_logo}
                webp={litd_logo_webp}
                alt="Lao IT Development Co., Ltd."
                style={{ width: '100%', height: 'auto' }}
              />
            </Col>
          </Row>

        </div>
      </React.Fragment>
    )
  }
}

export default PowerBy