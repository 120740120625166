import * as actionTypes from './../../../constants/actionTypes'

const initialState = {
  paymentDetails: {},
  // paymentDetails: {
  //   agreement: true,
  //   code: "BFH1519S4WZJD994",
  //   created_at: "2019-11-15T16:14:57.643662803Z",
  //   email: "noy.devsecs@gmail.com",
  //   emergency_contact: "77777777",
  //   id: 25769803777,
  //   phone: "55555555",
  //   status: "PENDING_PAYMENT",
  //   team_name: "thebest",
  //   updated_at: "2019-11-15T16:14:57.643663325Z"
  // }
  //   id(pin): 25769803851
  // created_at(pin): "2019-11-25T01:37:55.290803514Z"
  // updated_at(pin): "2019-11-25T01:37:55.29080392Z"
  // team_name(pin): "thebest"
  // code(pin): "BFH604LLJKJG9AAC"
  // email(pin): "souksakhone@laoitdev.com"
  // phone(pin): "+8562077492587"
  // emergency_contact(pin): "+8562055555555"
  // agreement(pin): true
  // status(pin): "PENDING_PAYMENT"
}

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVEPAYMENTDETAILS:
      state = Object.assign({}, state, { paymentDetails: action.payload })
      return state
    default:
      return state
  }
}