import React from 'react'
import { Modal, Select } from 'antd'

const {Option} = Select
export const StatusModal = ({visible, handleCancel, item}) => {
  const handleOk = () => {
    
  }
  return (
    <>
      <Modal
          title="Basic Modal"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{hidden: true}}
        >
        <Select style={{width: '100%'}} value={item}>
            <Option value="Pending">ລໍຖ້າການຈ່າຍ</Option>
            <Option value="Denied">ຈ່າຍສຳເລັດແລ້ວ</Option>
            <Option value="Success">ສຳເລັດແລ້ວ</Option>
            <Option value="Complete">ຈ່າຍເງິນບໍ່ສຳເລັດ</Option>
            <Option value="Cancel">ຍົກເລີກ</Option>
        </Select>
      </Modal>
    </>
  )
}