import { combineReducers } from 'redux';
import regisReducer from './../registration/views/registration/reducers'
import collapseReducer from './../registration/views/admin/reducers/collapseReducer'
import paymentReducer from './../registration/views/payment/reducers'
import searchReducer from '../registration/views/attendeecheck/reducer';
const rootReducer = combineReducers({
  register: regisReducer,
  payment: paymentReducer,
  collapsed: collapseReducer,
  search: searchReducer
})

export default rootReducer