import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { Row, Col, Button, Icon } from 'antd'
import Image from 'react-image-webp'
import moment from "moment";
import Barcode from 'react-barcode'
// import kuanjai_logo from './../../../assets/images/kuanjai_logo.svg'
// import kuanjai_logo_webp from './../../../assets/images/kuanjai_logo.webp'
import ticket_img from '../../../assets/images/VVF20-02.png'


class SearchResult extends Component {

  state = {
    is_paid: false,
    loading: true
  }

  gotowebsite = () => {

  }


  render() {
    const { searchvalue } = this.props.search
    // const { loading } = this.state
    // console.log(searchvalue);
    return (
      <>
        {!isMobile &&
          <>
            <div
              className="container-round-border mg-top-20"
              style={{ marginLeft: "20%", marginRight: "20%" }}
            >

              <Row type="flex" justify="center">
                {/*  */}
                {
                  <>
                    <div style={{ paddingBottom: "50px", paddingTop: "30px" }}>
                      <Row type="flex" justify="center">
                        <span
                          style={{ color: "green", fontSize: "18pt", fontFamily: "Noto Sans Lao Defago" }}>
                          ການຊຳລະເງິນຂອງທ່ານສຳເລັດແລ້ວ
												</span>
                      </Row>
                      <Row type="flex" justify="center">
                        <span
                          style={{ color: "green", fontSize: "18pt", fontFamily: "Noto Sans Lao Defago" }}>
                          Your Payment is Completed
												</span>
                      </Row>
                      {/* <Row type="flex" justify="center">
                      <Image
                        src={successLogo}
                        webp={successLogoWebp}
                        alt="Lao IT Development Co., Ltd."
                        style={{ width: '20%', height: '20%' }}
                      />
                    </Row> */}
                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />

                      <div style={{
                        backgroundImage: `url(${ticket_img})`, backgroundSize: "100% 100%", width: "600px", height: "400px", paddingTop: "8%", paddingBottom: "100%"
                      }}
                      >
                        <Row type="flex" justify="center" style={{ paddingBottom: "11%" }}>
                          <Barcode
                            displayValue={true}
                            value={searchvalue.book.id}
                            height={30}
                            fontSize={14}
                            width={1}
                          />
                        </Row>
                        <br />
                        <Row type="flex" justify="center" style={{ paddingBottom: "2%" }}>
                          <span
                            style={{ color: "white", fontSize: "17pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bold", textAlign: "center" }}
                          >
                            {searchvalue.book.attendee.first_name} {searchvalue.book.attendee.last_name} || {searchvalue.book.attendee.gender}
                          </span>
                        </Row>
                        <Row type="flex" justify="center">
                          <span style={{ color: "white", fontSize: "50pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bold" }}>
                            {searchvalue.book.quantity}
                          </span>
                        </Row>
                        <br />

                      </div>
                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />

                    </div>
                  </>
                }

              </Row>

            </div>

            <br />
            <Row type="flex" justify="center" gutter={48}>
              <Col>
                <a href="https://vvf.ewent.la/" >
                  <span
                    style={{ color: "#FA4700", textDecoration: "underline", fontSize: "12pt" }}
                    className="txt-bold txt-submit-btn"
                  >
                    ກັບສູ່ໜ້າລົງທະບຽນ / Back to Register Form
                                                    </span>
                </a>
              </Col>
            </Row>

          </>
        }

        {
          isMobile &&
          <>
            <div className="container-round-border mg-left-20 mg-right-20" >

              <Row type="flex" justify="center">
                {/* {
                  loading &&
                  // <img src={loadingPic} />
                } */}
                {
                  <>
                    <div style={{ paddingBottom: "25px", paddingTop: "20px" }}>
                      <Row type="flex" justify="center">
                        <span style={{ color: "green", fontSize: "16pt", fontFamily: "Noto Sans Lao Defago" }}>ການຊຳລະເງິນຂອງທ່ານສຳເລັດແລ້ວ</span>
                      </Row>
                      <Row type="flex" justify="center">
                        <span style={{ color: "green", fontSize: "16pt", fontFamily: "Noto Sans Lao Defago" }}>Your Payment is Completed</span>
                      </Row>
                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />

                      <div style={{
                        backgroundImage: `url(${ticket_img})`, backgroundSize: "100% 100%", width: "90vw", paddingTop: "10%",
                        paddingBottom: "60%"
                      }}
                      >
                        <Row type="flex" justify="center" style={{ paddingBottom: "15%" }}>
                          <Barcode
                            displayValue={true}
                            value={searchvalue.book.id}
                            height={15}
                            fontSize={9}
                            width={1}
                          />
                        </Row>
                        <Row type="flex" justify="center" style={{ paddingBottom: "3%" }}>
                          <span
                            style={{ color: "white", fontSize: "10pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", textAlign: "center" }}
                          >
                            {searchvalue.book.attendee.first_name} {searchvalue.book.attendee.last_name} || {searchvalue.book.attendee.gender}
                          </span>
                        </Row>
                        <Row type="flex" justify="center">
                          <span style={{ color: "white", fontWeight: "bold", fontSize: "30pt", fontFamily: "Noto Sans Lao Defago" }}>
                            {searchvalue.book.quantity}
                          </span>
                        </Row>
                      </div>
                      <br />
                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />

                    </div>
                  </>
                }

              </Row>

            </div>
            <br />
            <Row type="flex" justify="center" gutter={48}>
              <Col>
                <a onClick={e => { this.cancleRegister() }} >
                  <span
                    style={{ color: "#FA4700", textDecoration: "underline", fontSize: "12pt" }}
                    className="txt-bold txt-submit-btn"
                  >
                    ກັບສູ່ໜ້າລົງທະບຽນ / Back to Register Form
                                                    </span>
                </a>
              </Col>
            </Row>
          </>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  search: state.search
})

export default connect(mapStateToProps)(SearchResult)