import React, { Component } from "react";
import { Form, Icon, Input, Button, Col, Row, Card, Alert } from "antd";
// import bfh_logo_2020 from "./../../../assets/images/BFH_2020.png";
import { axiosInstant } from "../../../../service/axios";
import LocalStorageService from './../../../../service/LocalStorageService';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        axiosInstant
          .post("/users/login", values)
          .then(response => {
            // const _token = response.data.access_token;
            // setToken(_token);
            LocalStorageService.setToken(response.data)
            this.props.history.push("/nimda");
          })
          .catch(e => {
            this.setState({ visable: true });
          });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row style={{ fontFamily: "Phetsarath OT" }}>
        <Col
          md={{ span: 12, offset: 5 }}
          sm={{ span: 12, offset: 5 }}
          lg={{ span: 7, offset: 9 }}
          xl={{ span: 6, offset: 9 }}
        >
          <div style={{ width: "100%", textAlign: "center", margin: "20px 0" }}>
            {/* <img src={bfh_logo_2020} alt="" width="300px" /> */}
          </div>
          <Card title="ລົງທະບຽນ">
            {this.state.visable ? (
              <Alert
                message="ອີເມວ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ"
                type="error"
                closable
                afterClose={this.handleClose}
              />
            ) : null}
            <Form
              onSubmit={this.handleSubmit}
              className="login-form"
              style={{ top: "50%" }}
            >
              <Form.Item>
                {getFieldDecorator("username", {
                  rules: [
                    { required: true, message: "ກະລຸນາປ້ອນອີເມວ!" }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Email"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "ກະລຸນາປ້ອນລະຫັດຜ່ານ!" },
                    { min: 8, message: "ລະຫັດຕ້ອງມີ 8 ຕົວ" }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Password"
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  block
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(Login);
