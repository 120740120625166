import React, { Component } from 'react'
import { connect } from 'react-redux'
import BannerComponent from '../../components/banner'
import PowerBy from '../../components/powerby'
import SearchResult from './components/result'
import { Row, Form, notification, Button, Spin, Col } from 'antd'
import { CreateCheckForm } from './components/form'
import moment from 'moment'
// import { axiosInstant } from '../../../service/axios'
import { endpoint, clientEndpoint } from "./../../../config";
import { savesearch } from './action'

import axios from 'axios'
import { isMobile } from 'react-device-detect'
import InDevelopment from '../Errors/exception/indev'

import Image from 'react-image-webp'
import bcelCode from '../../assets/images/bcel_code.jpg'
import bcelCodeWebp from '../../assets/images/bcel_code.webp'
import { axiosInstant } from '../../../service/axios'

const AttendeeForm = ({
  transectioncodeErr,
  lastnameErr,
  form
}) => {
  return <CreateCheckForm
    transectioncodeErr={transectioncodeErr}
    lastnameErr={lastnameErr}
    form={form}
  />
}

class AttendeeCheckForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formsubmitted: false,
      loading: false,
      search_success: false
    }
  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

  search = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (!err) {
        this.setState({
          formsubmitted: true,
          loading: true
        })
        let transection_code = fieldsValue['transection_code_0']

        // axios.get(`${endpoint}/bookings/status?firstName=${firstname}&lastName=${lastname}&birthDate=${dob}`).then((res) => {
        axiosInstant.get(`/books/transactions/${transection_code}`)
          .then((res) => {
            // console.log(res)
            if (res.status === 200) {
              // this.props.savesearch(res.data)
              // this.setState({
              //   loading: false,
              //   formsubmitted: true
              // })

              setTimeout(() => { window.location.href = `/checkpayment?bookingcode=${res.data.book.id}` }, 1000)

            } else {
              this.setState({
                formsubmitted: false,
                loading: false,
              }, () => {
                notification.warning({
                  message: <span className="txt-alert">ເກີດຂໍ້ຜິດພາດ! Error!</span>,
                  description: <span className="txt-alert">Please Try again! ກະລຸນາລອງໃໝ່ອີກຄັ້ງ</span>,
                  style: {
                    backgroundColor: '#ffffffff',
                    border: 'solid 1px red'
                  },
                  duration: 3
                })
              })
            }
          }).catch((err) => {
            this.setState({
              loading: false,
              formsubmitted: false
            }, () => {
              notification.warning({
                message: <span className="txt-alert">ເກີດຂໍ້ຜິດພາດ! Error!</span>,
                description: <span className="txt-alert">Please Try again! ກະລຸນາລອງໃໝ່ອີກຄັ້ງ</span>,
                style: {
                  backgroundColor: '#ffffffff',
                  border: 'solid 1px red'
                },
                duration: 3
              })
            })
          })
      } else {
        this.setState({
          loading: false,
          formsubmitted: false
        }, () => {
          notification.warning({
            message: <span className="txt-alert">ເກີດຂໍ້ຜິດພາດ! Error!</span>,
            description: <span className="txt-alert">Please Fill in the form completely! ກະລຸນາຕື່ມຂໍ້ມູນໃນຟອມໃຫ້ສຳເລັດກ່ອນ</span>,
            style: {
              backgroundColor: '#ffffffff',
              border: 'solid 1px red'
            },
            duration: 3
          })
        })
      }
    })
  }

  render() {
    const { formsubmitted, loading } = this.state
    const { searchvalue } = this.props.search
    const { isFieldTouched, getFieldError } = this.props.form
    const transectioncodeErr = isFieldTouched(`transection_code_0`) && getFieldError(`transection_code_0`);

    return (
      <div>
        <BannerComponent />
        <Row type="flex" justify="center" className={!isMobile ? "mg-left-100 mg-right-100 mg-top-20" : "mg-top-20"} style={{ marginBottom: '50px' }}>
          {
            formsubmitted !== true && loading !== true &&
            <>

              {isMobile &&
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="form-label-25">
                  <div className="container-round-border" style={{ padding: isMobile ? "20px" : "50px" }}>
                    <Row>ຕົວຢ່າງ / Example :</Row>
                    <Row>
                      <Image
                        src={bcelCode}
                        webp={bcelCodeWebp}
                        alt="Lao IT Development Co., Ltd."
                        style={{ width: '40%', height: '40%', marginLeft: "auto", marginRight: "auto", display: "block" }}
                      />
                    </Row>
                  </div>
                </Col>
              }

              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="form-label-25">
                <div className="container-round-border" style={{ padding: isMobile ? "20px" : "50px" }}>
                  <Form onSubmit={this.search} style={{ width: '100%' }}>
                    <AttendeeForm
                      transectioncodeErr={transectioncodeErr}
                      form={this.props.form}
                    />
                    <div className="mg-top-20">
                      <Row type="flex" justify="center" align="middle">
                        <Button
                          style={{ backgroundColor: 'red', width: 'auto', height: '50px', borderRadius: '10px' }}
                          htmlType="submit">
                          <span
                            className="white-txt txt-bold txt-submit-btn"
                          >
                            ກວດສອບສະຖານະ / Check Status
                     </span>
                        </Button>
                      </Row>
                    </div>
                  </Form>
                </div>
              </Col>

              {!isMobile &&
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="form-label-25">
                  <div className="container-round-border" style={{ padding: isMobile ? "20px" : "50px" }}>
                    <Row>ຕົວຢ່າງ / Example :</Row>
                    <Row>
                      <Image
                        src={bcelCode}
                        webp={bcelCodeWebp}
                        alt="Lao IT Development Co., Ltd."
                        style={{ width: '40%', height: '40%' }}
                      />
                    </Row>
                  </div>
                </Col>
              }
              <div className="mg-bottom-20 mg-top-30">
                <Row type="flex" justify="center" align="middle">
                  <a href="https://vvf.ewent.la/" type="button">
                    <span
                      className="txt-bold txt-submit-btn"
                      style={{ color: "#FA4700" }}
                    >ກັບສູ່ໜ້າລົງທະບຽນ / Back to Register Form
              </span>
                  </a>
                </Row>
              </div>
            </>
          }
          {
            formsubmitted === true && loading === true &&
            <Spin />
          }
          {
            formsubmitted === true && loading === false &&
            <SearchResult />
          }


          {/* <InDevelopment /> */}

        </Row>

        <PowerBy />
        {/* {
          formsubmitted !== true &&
          <PowerBy />
        } */}
      </div >
    )
  }
}

const mapStateToProps = state => ({
  search: state.search
})

const mapDispatchToProps = {
  savesearch
}

const AttendeeCheck = Form.create()(AttendeeCheckForm);

export default connect(mapStateToProps, mapDispatchToProps)(AttendeeCheck)