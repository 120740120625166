import React from "react";
import { Col, Row, Icon, Typography, Card } from "antd";
import { axiosInstant } from "./../../../../service/axios";
import { numberFormat } from "./../../../assets/js/custom";
import { Doughnut, Bar, Pie } from "react-chartjs-2";
import moment from "moment";
const { Title, Paragraph } = Typography;
class Dashboard extends React.Component {
    state = {
        totalRegister: null,
        totalTotalMoney: null,
        totalPayment: null,
        payment: null,
        labelBar: [],
        countDataBar: [],
        countDataBarPaidPerDay: [],
        countDataBarTicketQty: [],
        countDataBarTicketQtyPaidPerDay: [],
        totalSaleTicketQuantity: null,
        totalSaleTicketQuantityReceiveMoney: null,
        paymentStatus: {
            Pending: 0,
            Success: 0,
            Denied: 0,
            Complete: 0
        },
        paymentStatusName: {
            Pending: "ລໍຖ້າການຈ່າຍເງິນ",
            Success: "ຈ່າຍເງິນສຳເລັດ",
            Denied: "Confirm",
            Complete: "ສຳເລັດແລ້ວ"
        },
        paymentStatusColor: {
            Pending: "",
            Success: "",
            Denied: "",
            Complete: ""
        },
        man: 0,
        woman: 0,
        labelBikePrice: [],
        countDataBikePirce: [],
        colors: [],
        checkedInTrue: 0,
        checkedInFalse: 0
    };
    sortObject = o => {
        var sorted = {},
            key,
            a = [];

        for (key in o) {
            if (o.hasOwnProperty(key)) {
                a.push(key);
            }
        }

        a.sort();
        for (key = 0; key < a.length; key++) {
            sorted[a[key]] = o[a[key]];
        }
        return sorted;
    };
    paymentStatus = status => {
        let name = "",
            statusName = "";
        switch (status) {
            case "Pending":
                name = "ລໍຖ້າການຊຳລະເງິນ";
                statusName = "Pending";
                break;
            case "Success":
                name = "ຈ່າຍເງິນແລ້ວ";
                statusName = "Success";
                break;
            case "Denied":
                name = "Confirm";
                statusName = "Denied";
                break;
            case "Complete":
                statusName = "Complete";
                name = "ສຳເລັດແລ້ວ";
                break;
            default:
                break;
        }
        return { name: name, status: statusName, counting: 1 };
    };
    randomColor = () => {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    componentDidMount() {
        const report = '/books';
        Promise.all([axiosInstant.get(report)]).then(([{ data: { books } }]) => {
            const getData = books.filter(books => books.status !== "Cancel").reduce((prev, cur) => {
                const { status, created_at, id, quantity } = cur;
                const newItem = { attendee: cur.attendee, status, created_at, id, ticket: cur.ticket, quantity }
                return prev.concat(newItem)
            }, [])
            const reportRegister = getData.filter(item => {
                return item.status !== "Cancel";
            });
            let money = 0,
                payment = 0,
                labelBar = [],
                countDataBar = [],
                countDataBarPaidPerDay = [],
                countDataBarTicketQty = [],
                countDataBarTicketQtyPaidPerDay = [],
                paymentStatus = {
                    Pending: 0,
                    Success: 0,
                    // Denied: 0,
                    Complete: 0
                },
                paymentStatusColor = {
                    Pending: "",
                    Success: "",
                    // Denied: "",
                    Complete: ""
                },
                paymentStatusName = {
                    Pending: "ລໍຖ້າການຈ່າຍເງິນ",
                    Success: "ຈ່າຍເງິນສຳເລັດ",
                    // Denied: "ຈ່າຍເງິນຖືກປະຕິເສດ",
                    Complete: "ສຳເລັດແລ້ວ"
                },
                woman = 0,
                man = 0,
                checkBikePrice = [],
                labelBikePrice = [],
                countDataBikePirce = [],
                color = [],
                inStatus = ["Success", "Pending", "Denied", "Complete"],
                checkedInTrue = 0,
                checkedInFalse = 0;
            getData.forEach(item => {
                // console.log(item);
                // Loop
                money += // all money
                    item.ticket !== undefined
                        ? item.ticket.price * item.quantity
                        : 0;
                payment += // payment
                    item.status === "Success" || item.status === "Complete"
                        ? item.ticket.price !== undefined
                            ? item.ticket.price * item.quantity
                            : 0
                        : 0;
                /* date register */
                let day = moment(item.created_at).format("DD/MM");
                let checkInclude = labelBar.includes(day);

                if (!checkInclude) {
                    labelBar.push(day);
                    countDataBar["day" + day] = 1;
                    countDataBarPaidPerDay["Success" + day] = 0;
                    countDataBarTicketQty["day" + day] = item.quantity;
                    countDataBarTicketQtyPaidPerDay["Success" + day] = 0;
                    if (item.status === "Success") {
                        countDataBarPaidPerDay["Success" + day] = 1;
                        countDataBarTicketQtyPaidPerDay["Success" + day] = item.quantity;
                    }
                } else {
                    countDataBar["day" + day] = countDataBar["day" + day] + 1;
                    countDataBarTicketQty["day" + day] = countDataBarTicketQty["day" + day] + item.quantity;
                    if (item.status === "Success") {
                        countDataBarPaidPerDay["Success" + day] = countDataBarPaidPerDay["Success" + day] + 1;
                        countDataBarTicketQtyPaidPerDay["Success" + day] = countDataBarTicketQtyPaidPerDay["Success" + day] + item.quantity;
                    }
                }
                /* date register */

                /* payment status */
                const resultPaymentStatus = this.paymentStatus(item.status);
                paymentStatus[resultPaymentStatus.status] +=
                    resultPaymentStatus.counting;
                paymentStatusName[resultPaymentStatus.status] =
                    resultPaymentStatus.name;
                paymentStatusColor[resultPaymentStatus.status] = this.randomColor();
                /* payment status */

                /* Gender */
                if (item.attendee.gender === "FEMALE") {
                    woman += 1;
                } else {
                    man += 1;
                }
                /* Gender */
                /* Bike Price */
                let checkStatus = inStatus.includes(item.status);
                if (checkStatus) {
                    if (item.ticket !== undefined) {
                        let categoryType = item.ticket.name;
                        checkInclude = checkBikePrice.includes(categoryType);
                        if (!checkInclude) {
                            color.push(this.randomColor());

                            checkBikePrice.push(categoryType);
                            labelBikePrice.push(categoryType);
                            countDataBikePirce["price" + categoryType] = 1;
                        } else {
                            countDataBikePirce["price" + categoryType] =
                                countDataBikePirce["price" + categoryType] + 1;
                        }
                    }

                }
                /* Bike Price */

                /* CheckedIn */
                if (item.status === "Complete") {
                    checkedInTrue++;
                } else {
                    checkedInFalse++;
                }
                /* CheckedIn */
            });

            this.setState({
                totalRegister: reportRegister.length,
                totalPayment: reportRegister.filter(item => item.status === "Success").length,
                totalTotalMoney: money,
                payment: payment,
                totalSaleTicketQuantity: reportRegister.length === 0 ? 0 : reportRegister.reduce((total, cur) => total.quantity !== undefined
                    ? total.quantity += cur.quantity
                    : total += cur.quantity),
                totalSaleTicketQuantityReceiveMoney: reportRegister
                    .filter(item => item.status === "Success").length === 0 ? 0 : reportRegister
                        .filter(item => item.status === "Success")
                        .reduce((total, cur) => total.quantity !== undefined
                            ? total.quantity += cur.quantity
                            : total += cur.quantity),
                labelBar: labelBar,
                countDataBar: countDataBar,
                countDataBarPaidPerDay: Object.assign({}, countDataBarPaidPerDay),
                countDataBarTicketQty: countDataBarTicketQty,
                countDataBarTicketQtyPaidPerDay: Object.assign({}, countDataBarTicketQtyPaidPerDay),
                paymentStatusName: paymentStatusName,
                paymentStatus: paymentStatus,
                paymentStatusColor: paymentStatusColor,
                woman: woman,
                man: man,
                labelBikePrice: labelBikePrice,
                countDataBikePirce: Object.assign({}, countDataBikePirce),
                colors: color,
                checkedInTrue: checkedInTrue,
                checkedInFalse: checkedInFalse
            });
        });
    }
    render() {
        const dataBar = {
            labels: this.state.labelBar,
            datasets: [
                {
                    label: "ທັງໝົດ",
                    backgroundColor: "rgba(255,99,132,0.2)",
                    borderColor: "rgba(255,99,132,1)",
                    borderWidth: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: Object.values(this.state.countDataBar)
                },
                {
                    label: "ຈ່າຍເງິນແລ້ວ",
                    backgroundColor: "rgba(50,255,100,0.2)",
                    borderColor: "rgba(50,255,100,1)",
                    borderWidth: 1,
                    hoverBackgroundColor: "rgba(50,255,100,0.4)",
                    hoverBorderColor: "rgba(50,255,100,1)",
                    data: Object.values(this.state.countDataBarPaidPerDay)
                }
            ]
        };

        const dataBarQty = {
            labels: this.state.labelBar,
            datasets: [
                {
                    label: "ທັງໝົດ",
                    backgroundColor: "rgba(255,99,132,0.2)",
                    borderColor: "rgba(255,99,132,1)",
                    borderWidth: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: Object.values(this.state.countDataBarTicketQty)
                },
                {
                    label: "ຈ່າຍເງິນແລ້ວ",
                    backgroundColor: "rgba(50,255,100,0.2)",
                    borderColor: "rgba(50,255,100,1)",
                    borderWidth: 1,
                    hoverBackgroundColor: "rgba(50,255,100,0.4)",
                    hoverBorderColor: "rgba(50,255,100,1)",
                    data: Object.values(this.state.countDataBarTicketQtyPaidPerDay)
                }
            ]
        };

        const allPayment = {
            labels: Object.values(this.state.paymentStatusName),
            datasets: [
                {
                    data: Object.values(this.state.paymentStatus),
                    backgroundColor: Object.values(this.state.paymentStatusColor),
                    hoverBackgroundColor: Object.values(this.state.paymentStatusColor)
                }
            ]
        };

        const dataGender = {
            labels: ["ເພດຊາຍ", "ເພດຍິງ"],
            datasets: [
                {
                    data: [this.state.man, this.state.woman],
                    backgroundColor: ["#FF6384", "#36A2EB"],
                    hoverBackgroundColor: ["#FF6384", "#36A2EB"]
                }
            ]
        };
        const dataBikePrice = {
            labels: this.state.labelBikePrice,
            datasets: [
                {
                    data: Object.values(this.state.countDataBikePirce),
                    backgroundColor: this.state.colors,
                    hoverBackgroundColor: this.state.colors
                }
            ]
        };
        const dataCheckedIn = {
            labels: ["ເຊັກອິນແລ້ວ", "ຍັງບໍ່ທັນເຊັນອິນ"],
            datasets: [
                {
                    data: [this.state.checkedInTrue, this.state.checkedInFalse],
                    backgroundColor: ["#FF6384", "#36A2EB"],
                    hoverBackgroundColor: ["#FF6384", "#36A2EB"]
                }
            ]
        };
        return (
            <>
               


                <Row gutter={16} style={{ margin: "10px 0" }}>
                    <Col className="guttar-row" lg={8} md={8} sm={24} xs={24}>
                        <Card title="ຈຳນວນຄົນເຊັກອິນ">
                            <Doughnut data={dataCheckedIn} />
                        </Card>
                    </Col>
                </Row>
            
            </>
        );
    }
}
export default Dashboard;
