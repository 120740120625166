const dotenv = require('dotenv');
dotenv.config();

module.exports = {
  endpoint: process.env.REACT_APP_API_ENDPOINT,
  clientEndpoint: process.env.REACT_APP_CLIENT_ENDPOINT,
  secretKey: process.env.REACT_APP_ENCRYPTION_SECRET,
  mcidDev: process.env.REACT_APP_MCID_DEV,
  mcidProd: process.env.REACT_APP_MCID_PROD,
  bendpoint: process.env.REACT_APP_BCEL_ENDPOINT
};