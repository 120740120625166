import React, { Component } from "react";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
import { axiosInstant } from "../../../../service/axios";
import moment from "moment";
import Axios from "axios";
const { Option } = Select;
class EditAttendee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoading: false
    };
  }

  componentDidMount() {

  }

  handleSubmit = e => {
    e.preventDefault();
    const item = this.props.item;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          attendee: {
            first_name: values.first_name,
            last_name: values.last_name,
            gender: values.gender,
            contact: {
              email: values.email,
              phone: values.phone,
            },
            quantity: item.attendee.quantity,
            race: { id: item.attendee.race.id }
          }
        }

        axiosInstant
          .put(`/books/${this.props.item.id}/attendees/${this.props.item.attendee.id}`, data)
          .then(res => {
            this.props.form.resetFields();
            if (res.status === 204) {
              axiosInstant
                .get(`/books/${this.props.item.id}/mail`)
                .then(res => {
                  if (res.status === 204) {
                    setTimeout(() => { window.location.reload() }, 2000)
                  }
                })
                .catch(error => {

                });
            }
          })
          .catch(error => { });
      } else {
        console.log(err)
      }
    });
  };
  render() {
    const { isLoading } = this.state
    const { getFieldDecorator } = this.props.form;
    const item = this.props.item;
    // console.log(item);
    return (

      <>
        {!isLoading &&
          <Form onSubmit={this.handleSubmit} className="login-form">
            <div className="gutter-example">
              <Row gutter={16}>
                <Col span={8}>
                  <div className="gutter-box">
                    <Form.Item label={<span>ຊື່ແທ້ Firstname</span>}>
                      {getFieldDecorator("first_name", {
                        initialValue: item.attendee.first_name,
                        rules: [
                          {
                            required: true,
                            message: "ກະລຸນາປ້ອນຊື່!",
                            whitespace: true
                          }
                        ]
                      })(<Input />)}
                    </Form.Item>
                  </div>
                </Col>
                <Col span={8}>
                  <Form.Item label={<span>ນາມສະກຸນ Lastname</span>}>
                    {getFieldDecorator("last_name", {
                      initialValue: item.attendee.last_name,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາປ້ອນຊື່!",
                          whitespace: true
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ເພດ Gender">
                    {getFieldDecorator(`gender`, {
                      validateTrigger: ["onChange", "onBlur"],
                      initialValue: item.attendee.gender,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາເລືອກເພດ"
                        }
                      ]
                    })(
                      <Select className="form-label-15" style={{ width: "100%" }}>
                        <Option className="form-label-15" value="">
                          Choose gender
                      </Option>
                        <Option className="form-label-15" value="MALE">
                          Male / ຊາຍ
                      </Option>
                        <Option className="form-label-15" value="FEMALE">
                          Female / ຍິງ
                      </Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="ເບີໂທລະສັບ Phone">
                    {getFieldDecorator("phone", {
                      initialValue: item.attendee.contact.phone,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາໃສ່ເບີໂທລະສັບ!",
                          whitespace: true
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={<span>ອີເມລ Email (ຕ້ອງໃຊ້ເພື່ອຮັບໃບບິນຮັບເງິນ)</span>}
                  >
                    {getFieldDecorator("email", {
                      initialValue: item.attendee.contact.email,
                      rules: [
                        {
                          type: "email",
                          message: "ຮູບແບບອີເມວບໍຖືກຕ້ອງ!"
                        },
                        {
                          required: true,
                          message: "ກະລຸນາປ້ອນ ອີເມວ!"
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>

              </Row>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                ບັນທຶກ
            </Button>
            </div>
          </Form>}
      </>
    );
  }
}

export default Form.create()(EditAttendee);
