import * as actionTypes from './../../../constants/actionTypes'

const initialState = {
  agreement: false,
  formvalue: {
    attendee:
    {
      first_name: "",
      last_name: "",
      gender: "",
      email: "",
      phone: "",
    },
    quantity: 1,
    ticket: {
      id: ""
    }
  },
  activeKey: "0",
  Agreechecked: false,
  enableNext: false,
  current: 0
}

export default function regisReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REGISTRATION:
      state = Object.assign({}, state, { formvalue: action.payload })
      return state
    case actionTypes.ENABLENEXT:
      state = Object.assign({}, state, { enableNext: action.payload })
      return state
    case actionTypes.SAVE_PANES:
      state = Object.assign({}, state, { panes: action.payload })
      return state
    case actionTypes.CHANGE_AFTERSUBMIT:
      state = Object.assign({}, state, { current: action.payload })
      return state
    default:
      return state
  }
}

