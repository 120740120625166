import React from 'react'
import { isMobile } from 'react-device-detect'
import { Row, Col, Input, DatePicker, Form, Icon } from 'antd'


const FormItem = Form.Item

export const CreateCheckForm = ({
  transectioncodeErr,
  form
}) => {
  return (
    <Row type="flex" gutter={48}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} className="form-label-25">
        <div className="container-round-border" style={{ padding: isMobile ? "20px" : "30px" }}>
          <Row type="flex" gutter={48}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="form-label-25">
              <Row>ເລກໃບບິນຮ້ານຄ້າ ຈາກແອັບ BCEL One<span style={{ color: "red" }}>*</span></Row>
              <Row>
                <FormItem validateStatus={transectioncodeErr ? 'error' : ''} help={transectioncodeErr || ''} >
                  {form.getFieldDecorator(`transection_code_0`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [{
                      required: true, message:
                        <Row type="flex" gutter={12}>
                          <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                          <Col>
                            <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ ເລກໃບບິນຮ້ານຄ້າ.</span></Row>
                            {/* <Row><span className="form-label-15 spn-red">Please input your firstname, English only.</span></Row> */}
                          </Col>
                        </Row>
                    }]
                  })(
                    <Input key={1} placeholder="ເລກໃບບິນຮ້ານຄ້າ" />
                  )}
                </FormItem>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>

    </Row >
  )
}