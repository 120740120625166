import React, { Component } from "react";
import { axiosInstant } from "../../../../service/axios";
import moment from "moment";
import { Card, Table, Tag, Icon, Divider, Button } from "antd";
import { exportExcelReport } from "./ExportExcelReport";
import { exportExcelReportAll } from "./ExportExcelReportAll"
import ReportSearch from "./ReportSearch";

class ReportRegister extends Component {
  state = {
    items: [],
    showClass: false,
    isLoad: false,
    searching: false
  };

  getAge = (dateNow, birthDate) => {
    var age = dateNow.format("YYYY") - birthDate.format("YYYY");
    var m = dateNow.format("MM") - birthDate.format("MM");
    if (m < 0 || (m === 0 && dateNow.format("DD") < birthDate.format("DD"))) {
      age--;
    }
    return age;
  };

  componentDidMount() {
    axiosInstant
      .get("/books")
      .then(({ data: { books } }) => {
        this.setState({
          items: books.sort(function (a, b) {
            var keyA = new Date(a.created_at),
              keyB = new Date(b.created_at);
            // Compare the 2 dates
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          }),
          isLoad: true
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  fetchData = () => {
    return this.state.items.map((item, i) => {
      const { attendee, created_at, id } = item;
      const age = this.getAge(moment(), moment(item.attendee.birth_day));
      return {
        key: attendee.id,
        number: i + 1,
        created_at: moment(created_at).format("DD/MM/YYYY"),
        full_name: `${attendee.first_name} ${attendee.last_name}`,
        code: id,
        gender: attendee.gender,
        email: attendee.email,
        phone: attendee.phone,
        price: item.ticket.price,
        distance: item.ticket.name,
        quantity: item.quantity,
        status: item,
        rowSpan: item.rowSpan
      };
    })
  }
  settingCondition(item) {
    let status = "";
    switch (item.status) {
      case "Pending":
        status = <Tag color="orange">ລໍຖ້າຈ່າຍເງິນ</Tag>;
        break;
      case "Success":
        status = <Tag color="green">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "Denied":
        status = <Tag color="red">ບໍ່ສາມາດໃຊ້ງານໄດ້</Tag>;
        break;
      case "Complete":
        status = <Tag color="green">ສຳເລັດແລ້ວ</Tag>;
        break;
      case "Cancel":
        status = <Tag color="red">ຍົກເລີກ</Tag>;
        break;
      default:
        status = "";
        break;
    }
    return status;
  }
  exportExcel = () => {
    exportExcelReport(this.state.items)
  };
  exportExcelAll = () => {
    exportExcelReportAll(this.state.items)
  }
  bindData = items => this.setState({ items, searching: true }); // BindData
  renderRow = (value, row) => ({ children: value, props: { rowSpan: row.rowSpan } })
  render() {
    const columns = [
      {
        title: "ລຳດັບ",
        dataIndex: "number",
        key: "number"
      },
      {
        title: "ວັນທີລົງທະບຽນ",
        dataIndex: "created_at",
        key: "created_at",
        render: this.renderRow
      },
      {
        title: "ລະຫັດລົງທະບຽນ",
        dataIndex: "code",
        key: "code",
        render: this.renderRow
      },
      {
        title: "ຊື່ ແລະ ນາມສະກຸນ",
        dataIndex: "full_name",
        key: "full_name"
      },
      {
        title: "ເພດ",
        dataIndex: "gender",
        key: "gender"
      },
      {
        title: "ອີເມວ",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "ເບີໂທ",
        dataIndex: "phone",
        key: "phone"
      },
      {
        title: "ລາຄາ",
        dataIndex: "price",
        key: "price",
      },
      {
        title: "ປະເພດບັດ",
        dataIndex: "distance",
        key: "distance",
      },
      {
        title: "ຈຳນວນບັດ",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "ສະຖານະ",
        dataIndex: "status",
        key: "status",
        render: (item, row) => this.renderRow(<span>{this.settingCondition(item)}</span>, row)
      }
    ];
    const pageLength = this.state.items ? this.state.items.length : 0;
    return (
      <>
        {this.state.isLoad && (
          <Card
            title="ລາຍການລົງທະບຽນ"
            extra={
              <>
                <Button type="link" onClick={this.exportExcel}>
                  <Icon type="file-excel" style={{ fontSize: "20px" }} />
                </Button>{" "}
                <Divider type="vertical" />{" "}
                <Button type="link" onClick={this.exportExcelAll}>
                  ExportAll
                </Button>
              </>
            }
          >
            <ReportSearch {...this.state} bindData={this.bindData} />

            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={this.fetchData()}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: [
                    "100",
                    "150",
                    "200",
                    "300",
                    "500",
                    pageLength.toString()
                  ],
                  position: "both"
                }}
              ></Table>
            </div>
          </Card>
        )}
      </>
    );
  }
}

export default ReportRegister;
