import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'antd'
import Image from 'react-image-webp'
import BannerComponent from '../../components/banner'
import { isMobile } from 'react-device-detect'

import logo_bcelpng from './../../assets/images/logo_bcel.png'
import logo_bcelwebp from './../../assets/images/logo_bcel.webp'
// import kcbuildingpng from './../../assets/images/KC_building.png'
// import kcbuildingwebp from './../../assets/images/KC_building.webp'

import { changeCurrent } from './../registration/actions'

class PaymentMethod extends Component {

  openOnepay = () => {
    // console.log(this.props.payment.paymentDetails);
    const { id } = this.props.payment.paymentDetails.book
    const path = `/invoices?bookingcode=${id}`
    setTimeout(() => { window.location.href = path }, 1000)
  }

  openSuccess = () => {
    this.props.changeCurrent(3)
  }

  render() {

    // const { code } = this.props.payment.paymentDetails

    // const path = `/invoices?bookingcode=${code}`

    return (
      <div>
        <BannerComponent />

        <div className="mg-top-20 mg-bottom-20">
          <Row type="flex" justify="center">
            <Col>
              <span className="form-title-red txt-center">ຮູບແບບຊຳລະເງິນ / Payment Method</span>
            </Col>
          </Row>
        </div>

        <div className="container-round-border mg-top-20 mg-left-50 mg-right-50" style={{ padding: '20px' }}>
          {/* <Row type="flex" className="mg-top-20" justify="space-around">
            <Col span={12}>
              <Row type="flex" justify="center">
                <span className="txt-header-bold txt-center txt-bold"><u>ຊໍາລະເງິນສົດ / Cash Payment</u></span>
              </Row>
              <Row type="flex" justify="center" className="mg-top-20">
                <span className="txt-header txt-center">ຊຳລະເງິນສົດໄດ້ທີ່ອາຄານ ເຄ & ຊີ</span>
              </Row>

              <Row type="flex" justify="center">
                <span className="txt-header txt-center">Cash payment is available at K&C Building</span>
              </Row>
              <Row type="flex" align="middle" justify="center">
                <span className="txt-header">
                  ທາງດ້ານຫລັງໂຮງຮຽນ ມສ ວຽງຈັນ, ບ້ານສີສະຫວາດ, ເມືອງຈັນທະບູລີ ນະຄອນຫລວງວຽງຈັນ.
                </span>
              </Row>
              <Row type="flex" justify="center">
                <span className="txt-header txt-details txt-center mg-top-10 mg-bottom-10">ສາມາດກົດເບິ່ງແຜນທີ່ດ້ານລຸ່ມ / Click the image below to Open Map</span>
              </Row>
              <Row type="flex" justify="center">
                <a href="https://goo.gl/maps/GrtdjMztQgF7tPop9" target="_blank" rel="noopener noreferrer">
                  {
                    !isMobile &&
                    <div>
                      <Image
                        src={kcbuildingpng}
                        webp={kcbuildingwebp}
                        alt="KC BUILDING"
                        style={{ width: '25vw', height: '20vw' }}
                      />
                    </div>
                  }

                  {
                    isMobile &&
                    <div className="mg-top-50">
                      <Image
                        src={kcbuildingpng}
                        webp={kcbuildingwebp}
                        alt="KC BUILDING"
                        style={{ width: '25vw', height: '20vw' }}
                      />
                    </div>
                  }
                </a>
              </Row>
            </Col>

            <Col span={12}>
              <Row type="flex" justify="center">
                <span className="txt-header-bold txt-center txt-bold"><u>ຊໍາລະເງິນຜ່ານ BCEL OnePay / Payment via BCEL OnePay</u></span>
              </Row>
              <Row type="flex" justify="center" className="mg-top-20">
                <span className="txt-header txt-center">ກົດດ້ານລຸ່ມນີ້ເພື່ອຮັບ QR Code ສຳລັບຈ່າຍເງິນ</span>
              </Row>
              <Row type="flex" justify="center">
                {!isMobile &&
                  <div className="mg-top-50" style={{ textAlign: "center" }}>
                    <Row align="middle">
                      <a href={path} target="_blank" rel="noopener noreferrer">
                        <Image
                          src={logo_bcelpng}
                          webp={logo_bcelwebp}
                          alt="BCEL OnePay"
                          style={{ width: '20vw', height: '20vw' }}
                        />
                      </a>
                    </Row>
                  </div>
                }

                {isMobile &&
                  <div className="mg-top-50">
                    <div style={{ width: '25vw', height: '25vw' }}>
                      <a href={path} target="_blank" rel="noopener noreferrer">
                        <Image
                          src={logo_bcelpng}
                          webp={logo_bcelwebp}
                          alt="BCEL OnePay"
                          style={{ width: '20vw', height: '20vw' }}
                        />
                      </a>
                    </div>
                  </div>
                }
              </Row>
            </Col>
          </Row> */}
          <Row type="flex" justify="center">
            <div className="container-round-border" style={{ boxShadow: '3px 3px', padding: '10px', width: '50vw' }}>
              <Row type="flex" justify="center">
                <span className="txt-header-bold txt-center txt-bold"><u>ຊໍາລະເງິນຜ່ານ BCEL OnePay / Payment via BCEL OnePay</u></span>
              </Row>

              <Row type="flex" align="middle" justify="center" style={{ padding: '20px' }}>
                {!isMobile &&
                  <div style={{ backgroundColor: '#ffffff', }}>
                    <Row align="middle">
                      <Image
                        src={logo_bcelpng}
                        webp={logo_bcelwebp}
                        alt="BCEL OnePay"
                        style={{ width: '5vw', height: '5vw' }}
                      />
                    </Row>
                  </div>
                }

                {isMobile &&
                  <div style={{ backgroundColor: '#ffffff', }}>
                    <div style={{ width: '5vw', height: '5vw' }}>
                      <Image
                        src={logo_bcelpng}
                        webp={logo_bcelwebp}
                        alt="BCEL OnePay"
                        style={{ width: '5vw', height: '5vw' }}
                      />
                    </div>
                  </div>
                }
              </Row>
              <Row type="flex" justify="center" style={{ padding: '10px' }}>
                <Button style={{ backgroundColor: '#ED6E26', boxShadow: '2px 2px' }} htmlType="submit" onClick={() => this.openOnepay()}>
                  <span className="txt-header txt-center txt-white">ກົດເພື່ອຮັບ QR Code / Generate QR Code</span>
                </Button>
              </Row>
            </div>
          </Row>

          {/* <Row type="flex" justify="center" style={{ paddingTop: '20px' }}>
            <div className="container-round-border" style={{ boxShadow: '3px 3px', padding: '10px', width: '50vw' }}>
              <Row type="flex" justify="center">
                <span className="txt-header-bold txt-center txt-bold"><u>ຊໍາລະເງິນສົດ / Cash Payment</u></span>
              </Row>
              <Row type="flex" justify="center">
                <a href="https://goo.gl/maps/GrtdjMztQgF7tPop9" target="_blank" rel="noopener noreferrer">
                  {
                    !isMobile &&
                    <div>
                      <Image
                        src={kcbuildingpng}
                        webp={kcbuildingwebp}
                        alt="KC BUILDING"
                        style={{ width: '25vw', height: '20vw' }}
                      />
                    </div>
                  }

                  {
                    isMobile &&
                    <div className="mg-top-50">
                      <Image
                        src={kcbuildingpng}
                        webp={kcbuildingwebp}
                        alt="KC BUILDING"
                        style={{ width: '25vw', height: '20vw' }}
                      />
                    </div>
                  }
                </a>
              </Row>
              <Row type="flex" justify="center" className="mg-top-20">
                <span className="txt-header txt-center">ຊຳລະເງິນສົດໄດ້ທີ່ອາຄານ ເຄ & ຊີ</span>
              </Row>
              <Row type="flex" justify="center">
                <span className="txt-header txt-center">Cash payment is available at K&C Building</span>
              </Row>
              <Row type="flex" justify="center">
                <span className="txt-header txt-center">ທາງດ້ານຫລັງໂຮງຮຽນ ມສ ວຽງຈັນ, ບ້ານສີສະຫວາດ, ເມືອງຈັນທະບູລີ ນະຄອນຫລວງວຽງຈັນ.</span>
              </Row>
              <Row type="flex" justify="center" style={{ padding: '10px' }}>
                <Button style={{ backgroundColor: '#ED6E26', boxShadow: '2px 2px' }} htmlType="submit" onClick={() => this.openSuccess()}>
                  <span className="txt-header txt-center txt-white">ຊຳລະເງິນສົດ / Cash Payment</span>
                </Button>
              </Row>
            </div>
          </Row> */}


          <div style={{ paddingTop: "25px" }}>
            <Row type="flex" justify="center" className="powerby-label">
              <span style={{ color: '#707070' }}>
                ຂໍອະໄພ ເນື່ອງຈາກທາງເຮົາບໍ່ມີນະໂຍບາຍໃນການຄືນເງິນ ພາຍຫຼັງທີ່ທ່ານຊໍາລະເງິນແລ້ວ.
              </span>
            </Row>
            <Row type="flex" justify="center" className="powerby-label">
              <span style={{ color: '#707070' }}>
                Sorry, we don't have a refund policy after you make a payment.
              </span>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  changeCurrent,
}

const mapStateToProps = (state) => ({
  payment: state.payment
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod)