import React from 'react'
import { Modal } from 'antd'
import { endpoint } from './../../../../config'
import { axiosInstant } from '../../../../service/axios'


export const QRModal = ({ visible, handleCancel, id, qr_img_path }) => {

  const handleOk = () => {

  }


  return (
    <>
      <Modal
        title="QR Payment"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
      >
        <img style={{ width: '100%' }} src={qr_img_path} alt="" />
      </Modal>
    </>
  )
}