import React, { Component } from 'react'
import BannerComponent from '../../../components/banner'
import PowerBy from '../../../components/powerby'

import loadingPic from './../../../assets/images/Infinity.gif'
import { Row, Col, Button, Modal } from 'antd'
import { axiosInstant } from '../../../../service/axios'
import history from '../../../../history'

import successLogo from '../../../assets/images/success-green-check-mark.png'
import successLogoWebp from '../../../assets/images/success-green-check-mark.webp'
import Image from 'react-image-webp'

import ticket_img from '../../../assets/images/VVF20-02.png'

import { isMobile } from 'react-device-detect'
import Barcode from 'react-barcode'


class CheckPayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      books: '',
      paid_status: 'SUCCESS',
      // paid_status: 'FAILED',
      paid_data: [],
      book: {},
      ticketModalVisible: false
      // paid_data : {result:0,id:3597112,txtime:"29/08/2020 14:26:29",age:434391.0000000000000000000000000000000012,name:"JALANA VONGXAY",phone:77805085,userid:"3AE0D7675BFB287FE054D8D385BC8CF0",ticket:"X59MNZK07WMT",fccref:"160FTBP20242A2K7",amount:5000,ccy:"LAK",lakamount:5000,iid:"59SWX6RZ",uuid:"HJQ8O6GU",description:"HJQ8O6GU",voidable:1}
    }

  }

  cancleRegister() {
    history.push({
      pathname: '/'
    })
  }

  componentDidMount = () => {
    var qry_params = window.location.search
    var qry = new URLSearchParams(qry_params)
    var books = qry.get('bookingcode')

    axiosInstant.get(`/books/${books}`).then((res) => {
      if (res.data !== null && res.data.book.status === "Success") {
        this.setState({ paid_data: res.data.book, loading: false, books: books })
      }
    }).catch((err) => {
      if (err) {
        this.setState({ falsed: true })
      }
    })

  }
  render() {
    const { loading, paid_status, book } = this.state
    const attendees = book.attendees
    // console.log(attendees);
    return (
      <div>
        <BannerComponent />

        {!isMobile &&
          <>
            <div
              className="container-round-border mg-top-20"
              style={{ marginLeft: "20%", marginRight: "20%" }}
            >

              <Row type="flex" justify="center">
                {
                  loading &&
                  <img src={loadingPic} />
                }
                {
                  !loading && paid_status === "SUCCESS" &&
                  <>
                    <div style={{ paddingBottom: "50px", paddingTop: "30px" }}>
                      <Row type="flex" justify="center">
                        <span
                          style={{ color: "green", fontSize: "18pt", fontFamily: "Noto Sans Lao Defago" }}>
                          ການຊຳລະເງິນຂອງທ່ານສຳເລັດແລ້ວ
												</span>
                      </Row>
                      <Row type="flex" justify="center">
                        <span
                          style={{ color: "green", fontSize: "18pt", fontFamily: "Noto Sans Lao Defago" }}>
                          Your Payment is Completed
												</span>
                      </Row>

                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />

                      <div style={{
                        backgroundImage: `url(${ticket_img})`, backgroundSize: "100% 100%", width: "450px", height: "300px", paddingTop: "8%", paddingBottom: "115%"
                      }}
                        onClick={() => { this.setState({ ticketModalVisible: true }) }}
                      >
                        <Row type="flex" justify="center" style={{ paddingBottom: "9%" }}>
                          <Barcode
                            displayValue={true}
                            value={'xxxxxxxx'}
                            height={25}
                            fontSize={12}
                            width={1.5}
                            margin={5}
                          />
                        </Row>
                        <br />
                        <Row type="flex" justify="center" style={{ paddingBottom: "1%" }}>
                          <span
                            style={{ color: "#da1a6d", fontSize: "13pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bold", textAlign: "center" }}
                          >
                            {this.state.paid_data.attendee.first_name} {this.state.paid_data.attendee.last_name} || {this.state.paid_data.attendee.gender}
                          </span>
                        </Row>
                        <Row type="flex" justify="center">
                          <span style={{ color: "#da1a6d", fontSize: "40pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bold" }}>
                            {this.state.paid_data.quantity}
                          </span>
                        </Row>
                        <br />

                      </div>

                      {/* modal */}
                      <Modal
                        visible={this.state.ticketModalVisible}
                        onCancel={() => { this.setState({ ticketModalVisible: false }) }}
                        centered={true}
                        footer={[<></>]}
                      // style={{  }}
                      >
                        <div style={{
                          backgroundImage: `url(${ticket_img})`, backgroundSize: "100% 100%", width: "450px", height: "300px", paddingTop: "8%", paddingBottom: "115%", marginLeft: "auto", marginRight: "auto"
                        }}
                          onClick={() => { this.setState({ ticketModalVisible: true }) }}
                        >
                          <Row type="flex" justify="center" style={{ paddingBottom: "10%" }}>
                            <Barcode
                              displayValue={true}
                              value={this.state.books}
                              height={25}
                              fontSize={12}
                              width={1.5}
                              margin={5}
                            />
                          </Row>
                          <br />
                          <Row type="flex" justify="center" style={{ paddingBottom: "1%" }}>
                            <span
                              style={{ color: "#da1a6d", fontSize: "13pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bold", textAlign: "center" }}
                            >
                              {this.state.paid_data.attendee.first_name} {this.state.paid_data.attendee.last_name} || {this.state.paid_data.attendee.gender}
                            </span>
                          </Row>
                          <Row type="flex" justify="center">
                            <span style={{ color: "#da1a6d", fontSize: "40pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bold" }}>
                              {this.state.paid_data.quantity}
                            </span>
                          </Row>
                          <br />

                        </div>
                      </Modal>
                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />

                    </div>
                  </>
                }
                {
                  !loading && paid_status === "FAILED" &&
                  <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                    <Row type="flex" justify="center">
                      <span style={{ color: "red", fontSize: "34px", fontFamily: "Noto Sans Lao Defago" }}>ເກີດຂໍ້ຜິດພາດ ກະລຸນາຈ່າຍເງິນອີກຄັ້ງ.</span>
                    </Row>
                    <Row type="flex" justify="center" gutter={48}>
                      <Col>
                        <Button style={{ backgroundColor: '#0003c4', width: 'auto', height: '50px', borderRadius: '10px' }} onClick={() => { window.history.back() }}  ><span className="white-txt txt-bold txt-submit-btn">ຍ້ອນກັບ</span></Button>
                      </Col>
                    </Row>
                  </div>
                }
              </Row>

            </div>

            <br />
            <Row type="flex" justify="center" gutter={48}>
              <Col>
                <a onClick={e => { this.cancleRegister() }} >
                  <span
                    style={{ color: "#FA4700", textDecoration: "underline", fontSize: "12pt" }}
                    className="txt-bold txt-submit-btn"
                  >
                    ກັບສູ່ໜ້າລົງທະບຽນ / Back to Register Form
                                                    </span>
                </a>
              </Col>
            </Row>

          </>
        }

        {isMobile &&
          <>
            <div className="container-round-border mg-top-20 mg-left-20 mg-right-20" >

              <Row type="flex" justify="center">
                {
                  loading &&
                  <img src={loadingPic} />
                }
                {
                  !loading && paid_status === "SUCCESS" &&
                  <>
                    <div style={{ paddingBottom: "25px", paddingTop: "20px" }}>
                      <Row type="flex" justify="center">
                        <span style={{ color: "green", fontSize: "16pt", fontFamily: "Noto Sans Lao Defago" }}>ການຊຳລະເງິນຂອງທ່ານສຳເລັດແລ້ວ</span>
                      </Row>
                      <Row type="flex" justify="center">
                        <span style={{ color: "green", fontSize: "16pt", fontFamily: "Noto Sans Lao Defago" }}>Your Payment is Completed</span>
                      </Row>
                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />

                      {/* ////////////// */}
                      <div style={{
                        backgroundImage: `url(${ticket_img})`, backgroundSize: "100% 100%", width: "90vw", paddingTop: "9%",
                        paddingBottom: "70%"
                      }}
                        onClick={() => { this.setState({ ticketModalVisible: true }) }}
                      >
                        <Row type="flex" justify="center" style={{ paddingBottom: "15%" }}>
                          <Barcode
                            displayValue={true}
                            value={"xxxxxxxx"}
                            height={20}
                            margin={5}
                            fontSize={10}
                            width={1}
                          />
                        </Row>
                        <Row type="flex" justify="center" style={{ paddingBottom: "3%" }}>
                          <span
                            style={{ color: "#da1a6d", fontSize: "10pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", textAlign: "center" }}
                          >
                            {this.state.paid_data.attendee.first_name} {this.state.paid_data.attendee.last_name} || {this.state.paid_data.attendee.gender}
                          </span>
                        </Row>
                        <Row type="flex" justify="center">
                          <span style={{ color: "#da1a6d", fontWeight: "bold", fontSize: "30pt", fontFamily: "Noto Sans Lao Defago" }}>
                            {this.state.paid_data.quantity}
                          </span>
                        </Row>
                      </div>

                      <Modal
                        visible={this.state.ticketModalVisible}
                        onCancel={() => { this.setState({ ticketModalVisible: false }) }}
                        centered={true}
                        footer={[<></>]}
                        bodyStyle={{ paddingRight: 0, paddingLeft: 0,paddingTop:"50px" }}
                      >
                        <div style={{
                          backgroundImage: `url(${ticket_img})`, backgroundSize: "100% 100%", width: "95vw", paddingTop: "9%",
                          paddingBottom: "70%",
                          marginRight: "auto", marginLeft: "auto"
                        }}
                        >
                          <Row type="flex" justify="center" style={{ paddingBottom: "15%" }}>
                            <Barcode
                              displayValue={true}
                              value={this.state.books}
                              height={20}
                              margin={5}
                              fontSize={10}
                              width={1}
                            />
                          </Row>
                          <Row type="flex" justify="center" style={{ paddingBottom: "3%" }}>
                            <span
                              style={{ color: "#da1a6d", fontSize: "10pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", textAlign: "center" }}
                            >
                              {this.state.paid_data.attendee.first_name} {this.state.paid_data.attendee.last_name} || {this.state.paid_data.attendee.gender}
                            </span>
                          </Row>
                          <Row type="flex" justify="center">
                            <span style={{ color: "#da1a6d", fontWeight: "bold", fontSize: "30pt", fontFamily: "Noto Sans Lao Defago" }}>
                              {this.state.paid_data.quantity}
                            </span>
                          </Row>
                        </div>
                      </Modal>


                      <br />
                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />

                    </div>
                  </>
                }
                {
                  !loading && paid_status === "FAILED" &&
                  <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                    <Row type="flex" justify="center">
                      <span style={{ color: "red", fontSize: "34px", fontFamily: "Noto Sans Lao Defago" }}>ເກີດຂໍ້ຜິດພາດ ກະລຸນາຈ່າຍເງິນອີກຄັ້ງ.</span>
                    </Row>
                    <Row type="flex" justify="center" gutter={48}>
                      <Col>
                        <Button style={{ backgroundColor: '#0003c4', width: 'auto', height: '50px', borderRadius: '10px' }} onClick={() => { window.history.back() }}  ><span className="white-txt txt-bold txt-submit-btn">ຍ້ອນກັບ</span></Button>
                      </Col>
                    </Row>
                  </div>
                }
              </Row>

            </div>
            <br />
            <Row type="flex" justify="center" gutter={48}>
              <Col>
                <a onClick={e => { this.cancleRegister() }} >
                  <span
                    style={{ color: "#FA4700", textDecoration: "underline", fontSize: "12pt" }}
                    className="txt-bold txt-submit-btn"
                  >
                    ກັບສູ່ໜ້າລົງທະບຽນ / Back to Register Form
                                                    </span>
                </a>
              </Col>
            </Row>
          </>
        }

        <div className="mg-top-20 mg-bottom-20 footer-container">
          <PowerBy />
        </div>
      </div >
    )
  }
}

export default CheckPayment