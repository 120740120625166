import React, { Component } from "react";
import {
  Card,
  Button,
  Divider,
  Table,
  Modal,
  Collapse,
  Form,
  Input,
  DatePicker,
  Select,
  Col,
  Row,
  Icon,
  Tag
} from "antd";
import { axiosInstant } from "./../../../../service/axios";
import AddCouPonModal from "./AddCouPonModal";
import moment from "moment";
import Excel from "exceljs";
import { saveAs } from "file-saver";
const { Panel } = Collapse;
const { Option } = Select;
function callback(key) {}
class Coupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      modalVisible: false,
      keyTale: []
    };
  }
  componentDidMount() {
    let coupons = axiosInstant.get("/coupons/");
    Promise.all([coupons])
      .then(([coupon]) => {
        this.setState({ items: coupon.data.reverse() });
      })
      .catch(e => {
        console.log(e);
      });
  }
  addShowModal = () => {
    this.setState({
      modalVisible: true
    });
  };
  handleOk = e => {
    this.setState({
      modalVisible: false
    });
  };

  handleCancel = e => {
    this.setState({
      modalVisible: false
    });
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter
    });
  };
  bindInsert = items => {
    this.setState({ items: [...items, ...this.state.items] });
    this.handleCancel();
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        axiosInstant
          .get("/coupons")
          .then(res => {
            let filtered = res.data;
            const filterCode = code => {
              return r => r.code === code;
            };
            const filterKind = Kind => {
              return r => r.kind === Kind;
            };
            const filterExpireAt = expired_at => {
              return r =>
                moment(r.expired_at).format("YYYY-MM-DD") ===
                moment(expired_at).format("YYYY-MM-DD");
            };
            const filterActive = () => {
              return r =>
                r.status === "ACTIVE" &&
                moment().format("YYYY-MM-DD HH:mm:s") <
                  moment(r.expired_at).format("YYYY-MM-DD HH:mm:s");
            };
            const filterUSED = () => {
              return r => r.status !== "ACTIVE";
            };
            const filterEXPIRED = () => {
              return r =>
                moment().format("YYYY-MM-DD HH:mm:s") >=
                moment(r.expired_at).format("YYYY-MM-DD HH:mm:s");
            };
            if (values.code) {
              filtered = filtered.filter(filterCode(values.code));
            }
            if (values.kind) {
              filtered = filtered.filter(filterKind(values.kind));
            }
            if (values.expired_at) {
              filtered = filtered.filter(filterExpireAt(values.expired_at));
            }
            if (values.status) {
              switch (values.status) {
                case "ACTIVE":
                  filtered = filtered.filter(filterActive());
                  break;
                case "USED":
                  filtered = filtered.filter(filterUSED());
                  break;
                case "EXPIRED":
                  filtered = filtered.filter(filterEXPIRED());
                  break;
                default:
                  break;
              }
            }
            this.setState({ items: filtered });
          })
          .catch(error => {});
      }
    });
  };
  exportExcel = e => {
    e.preventDefault();
    var workbook = new Excel.Workbook();
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    var worksheet = workbook.addWorksheet("Publications");
    worksheet.mergeCells("A1", "E1");
    worksheet.getCell("A1").value = "ລາຍການ Coupon";
    worksheet.getRow(2).values = ["Code", "ຊື່", "ປະເພດ", "ລາຄາ", "ໝົດອາຍຸ", "ສະຖານະ"];
    worksheet.columns = [
      { key: "code", width: 15 },
      { key: "name", width: 25 },
      { key: "kind", width: 10 },
      { key: "value" },
      { key: "expired_at" },
      { key: "status" }
    ];
    var firstRow = worksheet.getRow(1);
    firstRow.alignment = { vertical: "middle", horizontal: "center" };
    firstRow.height = 20;
    firstRow.font = { name: "Phetsarath OT", family: 4, size: 16, bold: true };

    var secondRow = worksheet.getRow(2);
    secondRow.font = { name: "Phetsarath OT", family: 4, size: 10, bold: true };
    secondRow.alignment = { vertical: "middle", horizontal: "center" };
    secondRow.height = 20;
    let count = 3;
    this.state.items.forEach(item => {
      worksheet.addRow({
        code: item.code,
        name: item.name,
        kind: item.kind,
        value: item.value,
        expired_at: moment(item.expired_at).format("DD/MM/YYYY H:mm:s"),
        status: item.status
          ? moment().format("YYYY-MM-DD HH:mm:s") <
            moment(item.expired_at).format("YYYY-MM-DD HH:mm:s")
            ? "Active"
            : "Expired"
          : "Used"
      });
      worksheet.getRow(count++).font = {
        name: "Phetsarath OT",
        family: 4,
        size: 12
      };
    });
    workbook.xlsx.writeBuffer().then(function(data) {
      var blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      saveAs(
        blob,
        `ລາຍການ Coupon ${moment(new Date()).format("DD/MM/YYYY H:mm:s")}.xlsx`
      );
    });
  };
  render() {
    const typCoupon = [
      {
        id: "AMOUNT",
        name: "ຈຳນວນເງິນ"
      },
      {
        id: "FREE",
        name: "ຝຣີ"
      },
      {
        id: "PERCENT",
        name: "ເປີເຊັນ"
      }
    ];
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "ລຳດັບ",
        dataIndex: "number",
        key: "number"
      },
      {
        title: "ປະເພດ",
        dataIndex: "kind",
        key: "kind"
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code"
      },
      {
        title: "ຊື່",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "ລາຄາ",
        dataIndex: "value",
        key: "value"
      },
      {
        title: "ໝົດອາຍຸວັນທີ",
        dataIndex: "expireAt",
        key: "expireAt",
        render: text => moment(text).format("DD/MM/YYYY H:mm:s")
      },
      {
        title: "ວັນທີສ້າງ",
        dataIndex: "created_at",
        key: "created_at",
        render: text => moment(text).format("DD/MM/YYYY H:mm:s"),
        sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
        sortDirections: ["descend", "ascend"]
      },
      {
        title: "ສະຖານະ",
        dataIndex: "isActive",
        key: "isActive",
        render: item => {
          return (
            <span>
              {item.status === "ACTIVE" ? (
                moment().format("YYYY-MM-DD HH:mm:s") <
                moment(item.expired_at).format("YYYY-MM-DD HH:mm:s") ? (
                  <Tag color="green">Active</Tag>
                ) : (
                  <Tag color="red">Expired</Tag>
                )
              ) : (
                <Tag color="orange">Used</Tag>
              )}
            </span>
          );
        }
      }
    ];
    const pageLength = this.state.items.length;
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({keyTable: selectedRowKeys});
      },
      getCheckboxProps: record => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name
      })
    };
    return (
      <>
        <Card
          title="ຄູປ໋ອງ"
          extra={
            <>
              <Button type="primary" onClick={this.addShowModal}>
                ເພີ່ມ ຄູປ໋ອງ
              </Button>
              <Divider type="vertical" />
              <Button type="link" onClick={this.exportExcel}>
                <Icon type="file-excel" style={{ fontSize: "20px" }} />
              </Button>
            </>
          }
        >
          <Collapse
            defaultActiveKey={["1"]}
            onChange={callback}
            style={{ marginBottom: "20px" }}
          >
            <Panel header="ຕົວກອງ" key="1">
              <Form onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                  <Col className="gutter-row" lg={6} sm={12}>
                    <Form.Item label="Code">
                      {getFieldDecorator("code")(<Input id="code" />)}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" lg={6} sm={12}>
                    <Form.Item label="ປະເພດຄູປ໋ອງ">
                      {getFieldDecorator("kind", {
                        initialValue: ""
                      })(
                        <Select>
                          <Option value="">ເລືອກ</Option>
                          {typCoupon.map(item => (
                            <Option key={item.id} value={item.id}>
                              {`${item.name}`}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" lg={6} sm={12}>
                    <Form.Item label="ໝົດອາຍຸວັນທີ">
                      {getFieldDecorator("expired_at")(
                        <DatePicker
                          placeholder="Select Time"
                          format="DD/MM/YYYY HH:mm:ss"
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" lg={6} sm={12}>
                    <Form.Item label="ສະຖານະ">
                      {getFieldDecorator("status", {
                        initialValue: ""
                      })(
                        <Select>
                          <Option value="">ເລືອກ</Option>
                          <Option value="ACTIVE">Active</Option>
                          <Option value="USED">Used</Option>
                          <Option value="EXPIRED">Expired</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" lg={24} sm={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        ຄົ້ນຫາ
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={this.state.items.map((item, i) => {
              return {
                key: item.id,
                number: (i += 1),
                code: item.code,
                kind: item.kind,
                name: item.name,
                value: item.value,
                expireAt: item.expired_at,
                created_at: item.created_at,
                isActive: item
              };
            })}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [
                "10",
                "20",
                "30",
                "50",
                "100",
                pageLength.toString()
              ],
              position: "both"
            }}
          ></Table>
        </Card>
        {this.state.modalVisible && (
          <Modal
            title="ເພີ່ມ Coupon"
            visible={this.state.modalVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            style={{ fontFamily: "Phetsarath OT" }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
          >
            <AddCouPonModal bindinsert={this.bindInsert} />
          </Modal>
        )}
      </>
    );
  }
}

export default Form.create()(Coupon);
