import React from "react";
import { axiosInstant } from "../../../../service/axios";
import {
  Card,
  Table,
  Tag,
  Modal,
  Input,
  Button,
  Icon,
  Tooltip,
  Divider,
  notification
} from "antd";
import Highlighter from "react-highlight-words";
import RegistrationDetail from "./RegistrationDetail";
// import { mergeInObjectArray } from "./../../../../helper";
import moment from "moment";
import EditAttendee from "./EditAttendee";
import EditAttendeeEmail from "./EditAttendeeEmail";
import { StatusModal } from "./StatusModal";
import { QRModal } from "./QRModal";
const { confirm } = Modal;
class Register extends React.Component {
  state = {
    items: [],
    visibleModalDetail: false,
    item: {},
    searchText: "",
    bikePirceItem: [],
    editVisible: false,
    editEmailVisible: false,
    isLoading: false,
    statusModal: false,
    status: "Pending",
    qrModal: false,
    id: "",
    qr_img_path: "",
    selectedRowKeys: []
  };
  showDetailModal = item => {
    // console.log(item);
    this.setState({ item: item, visibleModalDetail: true });
  };
  handleCancel = () => {
    this.setState({
      visibleModalDetail: false, editVisible: false,
      editEmailVisible: false
    });
  };

  getAge = (dateNow, birthDate) => {
    var age = dateNow.format("YYYY") - birthDate.format("YYYY");
    var m = dateNow.format("MM") - birthDate.format("MM");
    if (m < 0 || (m === 0 && dateNow.format("DD") < birthDate.format("DD"))) {
      age--;
    }
    return age;
  };

  componentDidMount() {
    axiosInstant.get('/books').then(({ data: { books } }) => {
      this.setState({
        items: books
          .filter(books => books.status !== "Cancel")
          .sort(function (a, b) {
            var keyA = new Date(a.created_at),
              keyB = new Date(b.created_at);
            // Compare the 2 dates
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          }), isLoading: true
      })
    })

    // console.log(this.fetchData());
  }
  fetchData = () => {
    return this.state.items.map((item, i) => {
      const { attendee, created_at, id, updated_by, updated_at, ticket } = item;
      return {
        key: id,
        number: i + 1,
        created_at: moment(created_at).format("DD/MM/YYYY H:mm:s"),
        full_name: `${attendee.first_name} ${attendee.last_name}`,
        code: id,
        gender: attendee.gender,
        email: attendee.email,
        phone: attendee.phone,
        distance: item.ticket.name,
        quantity: item.quantity,
        status: item,
        action: item,
        modifield_by: `${updated_by}, ${moment(updated_at).format("MM/DD/YYYY, h:mm a")}`,
        price: ticket.price
      };
    })
  }

  chageStatus = (code, status) => {
    let newStatus;
    if (status !== "CANCELED") {
      newStatus = this.state.items.map(item => {
        if (item.code === code) {
          item.status = status;
        }
        return item;
      });
    } else {
      newStatus = this.state.items.filter(item => {
        return item.code !== code;
      });
    }
    this.setState({ items: newStatus });
  };
  settingCondition(item) {
    let status = "";
    switch (item.status) {
      case "Pending":
        status = <Tag color="yellow">ລໍຖ້າຈ່າຍເງິນ</Tag>;
        break;
      case "Success":
        status = <Tag color="blue">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "Denied":
        status = <Tag color="red">ບໍ່ສາມາດໃຊ້ງານໄດ້</Tag>;
        break;
      case "Complete":
        status = <Tag color="green">ສຳເລັດແລ້ວ</Tag>;
        break;
      case "Cancel":
        status = <Tag color="red">ຍົກເລີກ</Tag>;
        break;
      default:
        status = "";
        break;
    }
    return status;
  }
  columnCell = r => {
    return {
      onClick: () => {
        this.showDetailModal(r);
      }
    };
  };
  clickStatus = r => {
    return {
      onClick: () => {
        this.changeSatus(r)
      }
    }
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape={true}
        textToHighlight={text.toString()}
      />
    )
  });
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    this.setState({ searchText: "" });
  };
  showModalEditAttendee = (e, item) => {
    e.preventDefault();
    // console.log(item);
    if (item.status === "Success") {
      // console.log(item.status);
      this.setState({
        editEmailVisible: true,
        item: item
      });
    }
    else {
      this.setState({
        editVisible: true,
        item: item
      });
    }

  };
  changeSatus = (item) => {
    this.setState({ status: item.status.status, statusModal: true })
  }
  showConfirm = (e, id, type, message) => {
    e.preventDefault();
    confirm({
      title: message,
      okText: "ຕົກລົງ",
      cancelText: "ບໍ່ຕົກລົງ",
      style: { fontFamily: "Phetsarath OT" },
      onOk: () => {
        switch (type) {
          case "mail":
            axiosInstant
              .get(`/books/${id}/mail`)
              .then(res => {
                if (res.status === 204) {
                  this.openNotificationWithIcon("success", "ສົ່ງເມວສຳເລັດແລ້ວ");
                }
              })
              .catch(error => {
                this.openNotificationWithIcon(
                  "error",
                  "ຍັງບໍ່ໄດ້ຈ່າຍເງິນ ບໍ່ສາມາດສົ່ງເມວໄດ້ອີກຄັ້ງ"
                );
              });
            break;
          case "cancel":
            axiosInstant
              .get(`/books/${id}/cancel`)
              .then(res => {
                if (res.status === 204) {
                  this.openNotificationWithIcon("success", "ຍົກເລີກສຳເລັດແລ້ວ");
                  this.setState({ items: this.state.items.filter(item => item.id !== id) })
                }
              })
              .catch(error => {
                this.openNotificationWithIcon(
                  "error",
                  "ຈ່າຍເງິນແລ້ວບໍ່ສາມາດຍົກເລີກໄດ້"
                );
              });
            break;
          case "confirm":
            axiosInstant
              .patch(`/books/${id}/status`, { status: "ON_HOLD" })
              .then(res => {
                if (res.status === 204) {
                  this.chageStatus(id, "ON_HOLD");
                  this.openNotificationWithIcon(
                    "success",
                    "confirm ສຳເລັດແລ້ວ"
                  );
                }
              })
              .catch(error => {
                this.openNotificationWithIcon(
                  "error"
                );
              });
            break;
          case "checkIn":
            axiosInstant
              .get(`/books/${id}/complete`)
              .then(res => {
                if (res.status === 204) {
                  this.chageStatus(id, "COMPLETED");
                  this.openNotificationWithIcon(
                    "success",
                    "Check In ສຳເລັດແລ້ວ"
                  );


                  const newItems = [...this.state.items];
                  newItems.forEach(item => {
                    if (item.id === id) {
                      item.status = "Complete";
                    }
                  });
                  this.setState({
                    items: newItems,
                  });
                }
              })
              .catch(error => {
                this.openNotificationWithIcon(
                  "error"
                );
              });
            break;

          case "cancel_all":
            this.state.selectedRowKeys.forEach(value => {
              axiosInstant
                .get(`/books/${value}/cancel`)
                .then(res => {
                  if (res.status === 204) {
                    this.setState({ items: this.state.items.filter(item => item.id !== value) })
                  }
                })
                .catch(error => {
                  this.openNotificationWithIcon(
                    "error",
                    "ບໍ່ສາມາດຍົກເລີກໄດ້"
                  );
                });
            })

            this.openNotificationWithIcon("success", "ຍົກເລີກສຳເລັດແລ້ວ");
            break;

          case "checkpayment":
            let count = 0;
            var pendingItems = this.state.items.filter(value => value.status === "Pending");
            pendingItems.forEach(value => {
              axiosInstant
                .get(`/books/${value.id}/onepay`)
                .then(res => {
                  count++;
                  if (count === pendingItems.length) {
                    this.openNotificationWithIcon(
                      "success",
                      "ກວດສອບການຈ່າຍເງິນສຳເລັດແລ້ວ"
                    );
                  }
                })
                .catch(error => {
                  count++;
                  if (count === pendingItems.length) {
                    this.openNotificationWithIcon(
                      "success",
                      "ກວດສອບການຈ່າຍເງິນສຳເລັດແລ້ວ"
                    );
                  }
                });
            })

            break;

          default:
            break;
        }
      },
      onCancel() { }
    });
  };

  getQR = (item) => {
    // axiosInstant.get(`/books/${item.id}/qr-code.png?couponCode=''`).then((res) => {
    //   if (res.data !== null) {
    //     this.setState({
    //       qr_img_path: `https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${res.data.emv_code}&choe=UTF-8`
    //     })

    //   }
    // })
    const path = `/invoices?bookingcode=${item.id}`
    window.open(`https://vvf.ewent.la${path}`, '_blank')

    this.setState({ id: item.id })
  }

  settingButton = item => {
    let buttonAction;
    const buttonConfirmDetail = (
      <>
        <Divider type="vertical" />
        <Tooltip placement="top" title="Confirm">
          <Button
            onClick={e =>
              this.showConfirm(
                e,
                item.id,
                "confirm",
                "ຍືນຍັນຂໍ້ມູນຖືກຕ້ອງທັງໝົດແລ້ວຫຼືບໍ່?"
              )
            }
            type="primary"
          >
            <Icon
              style={{ fontSize: "2em" }}
              type="check-square"
              theme="twoTone"
              twoToneColor="#28a745"
            />
          </Button>
        </Tooltip>{" "}
      </>
    );
    const buttonEdit = (
      <Tooltip placement="top" title="ແກ້ໄຂ">
        <Button type="link" onClick={e => this.showModalEditAttendee(e, item)}>
          <Icon
            type="edit"
            style={{ fontSize: "2em" }}
            theme="twoTone"
            twoToneColor="#ffc107"
          />
        </Button>
      </Tooltip>
    );

    const buttonCheckIn = (
      <Tooltip placement="top" title="ເຊັກອິນ">
        <Button type="link" onClick={e =>
          this.showConfirm(
            e,
            item.id,
            "checkIn",
            "ຍືນຍັນການເຂົ້າຮ່ວມງານ"
          )
        }>
          <Icon
            type="environment"
            style={{ fontSize: "2em" }}
            theme="twoTone"
            twoToneColor="#2EBD24"
          />
        </Button>
      </Tooltip>
    );

    switch (item.status) {
      case "Pending":
        buttonAction = (
          <>
            {/* <Divider type="vertical" /> {buttonEdit} */}
            <Divider type="vertical" />{" "}
            <Tooltip placement="top" title="QRCode">
              <Button
                type="link"
                theme="twoTone"
                target="_blank"
                onClick={e => this.getQR(item)}
              >
                <Icon style={{ fontSize: "2em" }} type="qrcode" />
              </Button>
            </Tooltip>
            <Divider type="vertical" />{" "}
            <Tooltip placement="top" title="ຍົກເລີກບັດ">
              <Button
                type="link"
                onClick={e =>
                  this.showConfirm(
                    e,
                    item.id,
                    "cancel",
                    "ທ່ານຕ້ອງການຍົກເລີກແທ້ຫຼືບໍ່?"
                  )
                }
              >
                <Icon
                  style={{ fontSize: "2em" }}
                  theme="twoTone"
                  twoToneColor="#dc3545"
                  type="close-circle"
                />
              </Button>
            </Tooltip>
          </>
        );
        break;
      case "PROCESSING":
        buttonAction = `${buttonEdit}${buttonConfirmDetail}`;
        break;

      case "Success":
        buttonAction = (
          <>
            {/* <Divider type="vertical" /> {buttonEdit} */}
            <Divider type="vertical" /> {buttonCheckIn}
          </>
        )
        break;
      default:
        break;
    }
    return (
      <>
        <Tooltip placement="top" title="Resend Email">
          <Button
            onClick={e =>
              this.showConfirm(
                e,
                item.id,
                "mail",
                "ຕ້ອງການຈະສົ່ງອີເມວອີກຄັ້ງຫຼືບໍ່?"
              )
            }
            type="link"
            theme="twoTone"
            target="_blank"
          >
            <Icon style={{ fontSize: "2em" }} type="mail" />
          </Button>
        </Tooltip>
        {buttonAction}
      </>
    );
  };
  openNotificationWithIcon = (type, messages) => {
    notification[type]({
      message: "ການແກ້ໄຂ",
      description: messages
    });
  };
  hideStatusModal = () => {
    this.setState({ statusModal: false, status: "Pending", qrModal: false, id: "" })
  }


  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const columns = [
      {
        title: "ລ/ດ",
        dataIndex: "number",
        key: "number",
        onCell: this.columnCell,
        className: "column-click"
      },
      {
        title: "ວັນທີລົງທະບຽນ",
        dataIndex: "created_at",
        key: "created_at",
        onCell: this.columnCell,
        className: "column-click",
        ...this.getColumnSearchProps("created_at"),
        render: (_, { created_at, status }) => ({ children: created_at, props: { rowSpan: status.rowSpan } })
      },
      {
        title: "ລະຫັດລົງທະບຽນ",
        dataIndex: "code",
        key: "code",
        onCell: this.columnCell,
        className: "column-click",
        ...this.getColumnSearchProps("code"),
        render: (_, row) => {
          return {
            children: row.code,
            props: {
              rowSpan: row.status.rowSpan,
            }
          }
        }
      },
      {
        title: "ຊື່ ແລະ ນາມສະກຸນ",
        dataIndex: "full_name",
        key: "full_name",
        onCell: this.columnCell,
        className: "column-click",
        sorter: (a, b) => a.full_name.localeCompare(b.full_name),
        ...this.getColumnSearchProps("full_name")
      },
      {
        title: "ເບີໂທ",
        dataIndex: "phone",
        key: "phone",
        onCell: this.columnCell,
        className: "column-click",
        sorter: (a, b) => a.phone - b.phone,
        ...this.getColumnSearchProps("phone"),
        render: (item, row) => {
          return <a href={`https://wa.me/85620${item.substr(-8, item.length)}`} target="_blank">{item.substr(-8, item.length)}</a>
        }
      },
      {
        title: "ປະເພດບັດ",
        dataIndex: "distance",
        key: "distance",
        onCell: this.columnCell,
        className: "column-click"
      },
      {
        title: "ຈຳນວນບັດ",
        dataIndex: "quantity",
        key: "quantity",
        onCell: this.columnCell,
        className: "column-click",
        ...this.getColumnSearchProps("quantity")
      },
      {
        title: "ສະຖານະ",
        dataIndex: "status",
        key: "status",
        render: (item, row) => ({ children: this.settingCondition(item), props: { rowSpan: row.status.rowSpan } }),
        onCell: this.clickStatus
      },
      {
        title: "ຕັ້ງຄ່າ",
        dataIndex: "action",
        key: "action",
        style: { width: "100px" },
        render: (item, row) => ({ children: this.settingButton(item), props: { rowSpan: row.status.rowSpan } })
      }, {
        title: "ແກ້ໄຂຊໍ້ມູນລ້າສຸດໂດຍ",
        dataIndex: "modifield_by",
        key: "modifield_by",
        onCell: this.columnCell,
        className: "column-click"
      },
    ];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const pageLength = this.state.items ? this.state.items.length : 0;
    // console.log(this.fetchData());
    return (
      <>
        <Card title={<><p>ລາຍການລົງທະບຽນ</p></>}>
          {selectedRowKeys.length > 0 && (
            <div>
              <Button
                type="danger"
                className="login-form-button"
                onClick={(e) => {
                  this.showConfirm(e, "1", "cancel_all", "ທ່ານຕ້ອງການຍົກເລີກລາຍຊື່ທີ່ເລືອກແທ້ຫຼືບໍ່?")
                }}
              >
                ຍົກເລີກລາຍຊື່ທີ່ເລືອກ
          </Button>
            </div>
          )}
          <div style={{ overflow: "auto" }}>
            {this.state.isLoading && <Table
              columns={columns}
              dataSource={
                this.state.items.length > 0 ? this.fetchData() : []
              }
              bordered
              rowSelection={rowSelection}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: [
                  "100",
                  "150",
                  "200",
                  "300",
                  "500",
                  pageLength.toString()
                ],
                position: "both"
              }}
            ></Table>}
          </div>
        </Card>
        {this.state.visibleModalDetail && (
          <Modal
            width="968px"
            visible={this.state.visibleModalDetail}
            title="ລາຍລະອຽດ"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            style={{ fontFamily: "Phetsarath OT" }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <RegistrationDetail item={this.state.item} />
          </Modal>
        )}
        {this.state.editVisible && (
          <Modal
            width="968px"
            visible={this.state.editVisible}
            title="ລາຍລະອຽດ"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            style={{ fontFamily: "Phetsarath OT" }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <EditAttendee item={this.state.item} />
          </Modal>
        )}

        {this.state.editEmailVisible && (
          <Modal
            width="968px"
            visible={this.state.editEmailVisible}
            title="ແກ້ໄຂອີເມວ"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            style={{ fontFamily: "Phetsarath OT" }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <EditAttendeeEmail item={this.state.item} />
          </Modal>
        )}

        {/* <StatusModal item={this.state.status} handleCancel={this.hideStatusModal} visible={this.state.statusModal} /> */}
        <QRModal
          id={this.state.id}
          handleCancel={this.hideStatusModal}
          visible={this.state.qrModal}
          qr_img_path={this.state.qr_img_path}
        />
      </>
    );
  }
}

export default Register;
