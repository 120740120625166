import React, { Component } from 'react'
import { Provider } from 'react-redux'
import {
  Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import store from './store/store'
import Registration from './registration/views/registrations'
import Err404Page from './registration/views/Errors/exception/404'
import Login from './registration/views/admin/authentication/login'
import Admin from './registration/views/admin/Admin'
import history from './history'
import QR_NormalPage from './registration/views/qr/qrpage'
import CheckPayment from './registration/views/admin/checkpayment/checkPaymenty'
import AttendeeCheck from './registration/views/attendeecheck'




class AppRoot extends Component {

  componentDidMount = () => {
    document.body.classList.add('notofont')
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact={true} path="/" component={Registration} />
            <Route path="/registration" component={Registration} />
            <Route path="/invoices" component={QR_NormalPage} />
            <Route path="/checkattendee" component={AttendeeCheck} />
            <Route path="/nimda/login" exact={true} render={props => {
              if (localStorage.getItem("_token")) {
                return <Redirect to="/" />
              } else {
                return <Login {...props} />
              }
            }} />
            <Route path="/nimda" component={Admin} />
            <Route path="/checkpayment" component={CheckPayment} />
            <Route component={Err404Page} />
            <Redirect to="/404" />
          </Switch>
        </Router>
      </Provider>
    )
  }
}

export default AppRoot