import React, { Component } from 'react'
import BannerComponent from '../../components/banner'
import { Row, Button, Col, Input, notification } from 'antd'
import PowerBy from '../../components/powerby'
import { endpoint } from './../../../config'
import './qr.css'
import history from '../../../history'
import { isMobile } from 'react-device-detect'
import { axiosInstant } from '../../../service/axios'
import { OnePay } from '../../assets/js/onepay'
import moment from 'moment'

class QR_NormalPage extends Component {
  state = {
    books: '',
    paid_data: {},
    disabled: false,
    qr_img_path: '',
    coupon: {},
    isLoading: true,
    hasCoupon: false,
    total_price: 0,
    couponValue: '',
    couponStatus: '',
    emv_code: '',
    falsed: false,
  }

  check = () => {
    history.push({
      pathname: '/checkpayment',
      state: { books: this.state.books }
    })
  }

  cancleRegister() {
    localStorage.removeItem("bookingCode");
    history.push({
      pathname: '/'
    })
  }



  componentDidMount = () => {
    var qry_params = window.location.search
    var qry = new URLSearchParams(qry_params)
    var books = qry.get('bookingcode')

    axiosInstant
      .get(`/books/${books}/onepay-emvcode`)
      .then((res) => {
        if (res.data !== null) {
          this.setState({
            books: books,
            emv_code: res.data.emv_code,
            qr_img_path: `https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${res.data.emv_code}&choe=UTF-8&date=${moment()}`,

          })

        }
      }).catch((err) => {
        if (err) {
          this.setState({ falsed: true })
        }
      })


    axiosInstant.get(`/books/${books}`).then((res) => {
      if (res.data !== null) {
        if (res.data.book.status === "Success") {
          localStorage.clear("bookingCode")
          setTimeout(() => { window.location.href = `/checkpayment?bookingcode=${books}` }, 3000)
        }
        else {
          this.setState({
            paid_data: res.data.book,
            isLoading: false,
            total_price: res.data.book.ticket.price,
            falsed: false
          })
          localStorage.setItem("bookingCode", books)
        }
      }
    }).catch((err) => {
      if (err) {
        this.setState({ falsed: true })
      }
    })

    const mcid = 'mch5fa4f6d2ced76'; // merchant id
    const uuid = books; // transaction id (please define as unique key)
    const onePay = new OnePay(mcid); // create new OnePay instance

    onePay.debug = false; // enable OnPay debug(Please looking on console log)

    // /* generate new QR code via onepay.js */
    // OnePay.getCode(
    //   {
    //     transactionid: uuid, // please define as unique key
    //     // invoiceid: null, // a invoice ID can pay many times OR have many transaction ID
    //     // terminalid: null, // terminal ID (in case have many terminals, POS devices or etc...)
    //     amount: 1, // invoice amount
    //     description: 'Test', // must define as English text
    //     expiretime: 15, // expire time must be minutes
    //   },
    //   // function (code) {
    //   //   $('.qr-code').attr(
    //   //     'src',
    //   //     'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=' +
    //   //     code +
    //   //     '&choe=UTF-8',
    //   //   ); // set QR code into image, Scan to pay
    //   //   $('.one-click-pay').attr('href', 'onepay://qr/' + code); // set QR code into button, One click pay(payment link) for mobile app integration
    //   // },
    // );

    /* define subscribe parameter(s) */
    const subParams = {
      uuid: uuid, // please specified uuid if would like to receive callback data only the transaction (for front-end subscribe)
    };
    /* subscribe to receiving OnePay callback*/
    onePay.subscribe(subParams, function (res) {
      setTimeout(() => { window.location.href = `/checkpayment?bookingcode=${books}` }, 3000)
    });

  }

  componentWillUnmount = () => {
    // localStorage.clear()
  }

  render() {

    const { books, paid_data, isLoading } = this.state

    return (
      < div >
        <BannerComponent />
        {!this.state.isLoading && (
          <div className="container-round-border mg-left-20 mg-right-20" style={{ padding: '20px' }}>

            <Row type="flex" justify="center">
              <span className="qr-title" >ສະແກນ QR Code ເພື່ອຊຳລະຜ່ານ BCEL Onepay</span>
            </Row>

            <Row type="flex" justify="center">
              <span className="qr-title" >Scan QR Code to Make Payment with BCEL Onepay</span>
            </Row>

            <br />

            {
              !isMobile &&
              <>
                <Row type="flex" justify="center">
                  <span className="qr-title-blue"
                    style={{ color: "black", fontSize: "18pt" }}
                  >{paid_data.attendee.first_name} {paid_data.attendee.last_name}</span>
                </Row>
                <Row type="flex" justify="center">
                  <span className="qr-title-blue"
                    style={{ color: "black", fontSize: "17pt" }}
                  >ຈຳນວນ Quantity : {paid_data.quantity} x ລາຄາ Price : {Intl.NumberFormat('ja-JP').format(paid_data.ticket.price)} &#8365;
              </span>
                </Row>
                <hr style={{ width: "50%" }} />
                <Row type="flex" justify="center">
                  <span
                    className="qr-title"
                    style={{ fontWeight: 'bold', fontSize: "20pt" }}
                  >
                    ລວມເປັນເງິນທັງໝົດ Total Amounts : {Intl.NumberFormat('ja-JP').format(this.state.total_price * this.state.paid_data.quantity)} &#8365;
                  </span>
                </Row>

              </>
            }

            {
              isMobile &&
              <>
                <Row type="flex" justify="center">
                  <span className="qr-title-blue"
                    style={{ color: "black", fontSize: "12pt" }}
                  >{paid_data.attendee.first_name} {paid_data.attendee.last_name}</span>
                </Row>
                <Row type="flex" justify="center">
                  <span className="qr-title-blue"
                    style={{ color: "black", fontSize: "11pt" }}
                  >ຈຳນວນ Quantity : {paid_data.quantity} x ລາຄາ Price : {Intl.NumberFormat('ja-JP').format(paid_data.ticket.price)} &#8365;
              </span>
                </Row>
                <hr style={{ width: "50%" }} />
                <Row type="flex" justify="center">
                  <span
                    className="qr-title"
                    style={{ fontWeight: 'bold', fontSize: "12pt" }}
                  > ລວມເປັນເງິນທັງໝົດ Total Amounts :
                </span>
                </Row>
                <Row type="flex" justify="center">
                  <span
                    className="qr-title"
                    style={{ fontWeight: 'bold', fontSize: "12pt" }}
                  > {Intl.NumberFormat('ja-JP').format(this.state.total_price * this.state.paid_data.quantity)} &#8365;
                </span>
                </Row>
              </>
            }

            <br />
            <Row type="flex" justify="center">
              <span>LAO IT DEVELOPMENT-E</span>
            </Row>
            <Row type="flex" justify="center">
              <img className="qr-img" src={this.state.qr_img_path} alt="" />
            </Row>

            {isMobile &&
              <>
                <Row type="flex" justify="center" gutter={48}>
                  <Col>
                    <Button
                      href={`onepay://qr/${this.state.emv_code}`}
                      style={{ backgroundColor: 'red', width: 'auto', height: '50px', borderRadius: '10px' }}>
                      <span className="white-txt txt-bold txt-submit-btn">ກົດເພື່ອຊຳລະເງິນຜ່ານ BCEL One / Open BCEL One</span>
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row type="flex" justify="center">
                  <span style={{ fontSize: "6pt", color: "red", textAlign: "center" }} >
                    ກໍລະນິທີ່ປຸ່ມ "ກົດເພື່ອຊຳລະເງິນຜ່ານ BCEL One" ແລ້ວພົບບັນຫາໃນການຊຳລະເງິນ, ກະລຸນາ Screenshot ໜ້າຈໍ QR Code ນີ້ເພື່ອໄປເປີດໃນແອັບ BCEL One ອີກຄັ້ງ.
                    </span>
                </Row>
              </>
            }

          </div>
        )
        }

        {this.state.falsed && (
          <>
            <br />
            <Row type="flex" justify="center">
              <span className="qr-title" style={{ color: "red " }} >ເກີດຂໍ້ຜິດພາດ, ກະລຸນາລົງທະບຽນໃໝ່!!!</span>
            </Row>
            <Row type="flex" justify="center">
              <span className="qr-title" style={{ color: "red " }} >Something Went Wrong, Please Register Again!!!</span>
            </Row>
          </>
        )}
        <br />
        <Row type="flex" justify="center">
          <Col>
            <a onClick={e => { this.cancleRegister() }} ><span style={{ color: "#FA4700", textDecoration: "underline" }} className="txt-bold txt-submit-btn">ກັບສູ່ໜ້າລົງທະບຽນ / Back to Register Form</span></a>
          </Col>
        </Row>
        <div className="mg-top-20 mg-bottom-20 footer-container">
          <PowerBy />
        </div>
      </div >
    )
  }
}

export default QR_NormalPage