import React from 'react'

import { Descriptions } from 'antd'

import { isMobile } from 'react-device-detect'

export const Summary = ({
  firstname,
  lastname,
  gender,
  phone,
  email,
  quantity
}) => {
  return (
    <div className="mg-top-20 hiddenoverflow-y"
      type="flex" justify="center" style={{ marginLeft: "auto", marginRight: "auto" }}>
      {
        !isMobile &&
        <Descriptions layout="vertical" bordered>
          <Descriptions.Item label="ຊື່ / First Name">
            <b>{firstname}</b>
          </Descriptions.Item>
          <Descriptions.Item label="ນາມສະກຸນ / Last Name">
            <b>{lastname}</b>
          </Descriptions.Item>
          <Descriptions.Item label="ເພດ / Gender">
            <b>{gender}</b>
          </Descriptions.Item>
          <Descriptions.Item label="ເບີໂທຕິດຕໍ່ / Contact Number">
            <b>{phone}</b>
          </Descriptions.Item>
          <Descriptions.Item label="ອີເມລ / E-Mail">
            <b>{email}</b>
          </Descriptions.Item>
          <Descriptions.Item label="ຈຳນວນປີ້ / Tickets">
            <b>{quantity}</b>
          </Descriptions.Item>

        </Descriptions>
      }

      {
        isMobile &&
        <Descriptions layout="vertical" bordered>
          <Descriptions.Item label="ຊື່ / First Name">
            <b>{firstname}</b>
          </Descriptions.Item>
          <Descriptions.Item label="ນາມສະກຸນ / Last Name">
            <b>{lastname}</b>
          </Descriptions.Item>
          <Descriptions.Item label="ເພດ / Gender">
            <b>{gender}</b>
          </Descriptions.Item>
          <Descriptions.Item label="ເບີໂທຕິດຕໍ່ / Contact Number">
            <b>{phone}</b>
          </Descriptions.Item>
          <Descriptions.Item label="ອີເມລ / E-Mail">
            <b>{email}</b>
          </Descriptions.Item>
          <Descriptions.Item label="ຈຳນວນປີ້ / Tickets">
            <b>{quantity}</b>
          </Descriptions.Item>
        </Descriptions>
      }
    </div>
  )
}